/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */

/* global URL, _VALOTA_LOG_CHROME_OS, _displayID, _VALOTA_REST, _applicationData, chrome, _CHROME_PWA_EXTENSION_ID */

// TODO enable parallelism
// TODO memory checks

if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}
valotaPWAFS = null;
ValotaEngine.PWA = {
	inited: false,
	PIN: null,
	callback: null,
	cacheQueue: [],
	files: {},
	videoOwners: [],
	screenshotCallback: false,
	overlayPlaying: false,
	emergencyActive: false,
	overlayData: null,
	start: function () {
		console.warn("[Engine PWA] start");
		if (!ValotaEngine.PWA.inited) {
			window.valotaPWAFS = new ValotaPWAFS();
			ValotaEngine.PWA.inited = true;
		}
		chrome.runtime.sendMessage(_CHROME_PWA_EXTENSION_ID, {action: "pwa_start"},
			function (response) {
				if (typeof response !== "undefined" && response.status === "ok") {
					if (response.lat !== null && response.lat != 0 && response.long != 0) {
						_LATITUDE = response.lat;
						_LONGITUDE = response.long;
					}
					if (response.deviceID !== "not enrolled") {
						sendMyDeviceID(response.deviceID);
					}

					if (response.displayID && _displayID !== response.displayID) {
						// got a different displayUUID from the app than what we are using, switching
						logError("switching displayUUID like extension dictates, new [" + response.displayID + "]", ValotaEngine.PWA.not_defined, "warning");

						_displayID = response.displayID;
						setLocal('display', _displayID);
						window.setTimeout(function () {
							console.log("reloading");
							reloadDisplay(false);
						}, 10000);
					}
				}
			}
		);
	},
	clearUUID: function () {
		chrome.runtime.sendMessage(_CHROME_PWA_EXTENSION_ID, {action: "clear_uuid"}, function (response) {
			logError("cleared displayUUID from disk " + response.status, ValotaEngine.PWA.not_defined, "info");
		});
	},
	saveUUID: function (uuid) {
		console.warn("[Engine PWA] save uuid", uuid);
		chrome.runtime.sendMessage(_CHROME_PWA_EXTENSION_ID, {action: "save_uuid", displayUUID: uuid}, function (response) {
			logError("saved displayUUID to disk " + response.status, ValotaEngine.PWA.not_defined, "info");
		});
	},
	getScreenshot: function (callback) {
		chrome.runtime.sendMessage(_CHROME_PWA_EXTENSION_ID, {action: "screenshot"}, function (response) {
			callback(response.image);
		});
	},
	reloadApp: function () {
		// not implemented
	},
	cacheFile: function (name, url, callback) {
//		console.log("[Engine PWA] loading", url);
		if (typeof ValotaEngine.PWA.files[name] !== 'undefined') {
			if (ValotaEngine.PWA.files[name].loading === true) {
				// this shouldn't happen
				throw "bad coding, already loading the file in " + url;
			}
			if (ValotaEngine.PWA.files[name].blobUrl !== null) {
				window.setTimeout(function () {
					callback(name, url, '', {status: 'ok'});
				}, 10);
				return true;
			}
		}
		ValotaEngine.PWA.files[name] = {};
		ValotaEngine.PWA.files[name].file = [];
		ValotaEngine.PWA.files[name].loading = true;
		ValotaEngine.PWA.files[name].url = null;
		ValotaEngine.PWA.files[name].blobUrl = null;
		valotaPWAFS.loadURL(name, url).then((data) => {
			ValotaEngine.PWA.files[name].blobUrl = data.blobUrl;
			ValotaEngine.PWA.files[name].loading = false;
			callback(name, url, '', {status: 'ok'});
		}).catch((e) => {
			ValotaEngine.PWA.files[name].loading = false;
			callback(name, url, '', {status: 'error', message: "couldn't load: " + e.message});
		});
		return true;
	},
	fileCachedCallback: function (name, url, unused, status) {
		console.warn("[Engine PWA] file cached with " + ValotaEngine.Video.cacheList[name].cb.length + " callbacks", url, status);
		for (var i = 0; i < ValotaEngine.Video.cacheList[name].cb.length; i++) {
			ValotaEngine.Video.cacheList[name].cb[i](url, status);
		}
		delete ValotaEngine.Video.cacheList[name];
	},
	playVideo: function (uuid, url, elem, playStartedCB, endedCB, errorCB, iframeRect) {
		var name = "v_" + JSON.stringify(url).replace(/[^0-9a-z]/gi, '');
		if ((!(name in ValotaEngine.PWA.files)) || ValotaEngine.PWA.files[name].loading) {
			console.error("[Engine PWA]", ValotaEngine.PWA.files, name);
			throw "trying to play an uncached video";
		}
		if (!(uuid in ValotaEngine.PWA.videoOwners)) {
			ValotaEngine.PWA.videoOwners[uuid] = {
				elem: null,
				videoStartedPlayingCB: null,
				videoEndedCB: null,
				videoErrorCB: null,
				videoConf: {mute: true}
			};
		}
		if (ValotaEngine.PWA.videoOwners[uuid].elem !== null) {
			if (ValotaEngine.PWA.videoOwners[uuid].elem === elem) {
				throw "bad coding, use replayVideo to replay a video :O";
			}
			throw "bad coding, a video set when trying to play video. stop first";
		}

		ValotaEngine.PWA.videoOwners[uuid].elem = elem;
		ValotaEngine.PWA.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.PWA.videoOwners[uuid].videoEndedCB = endedCB;
		ValotaEngine.PWA.videoOwners[uuid].videoErrorCB = function (e) {
			ValotaEngine.PWA.stopVideo(uuid, elem);
			console.error("video errored", e);
			valotaPWAFS.deleteFromCache(name);
			e.cmd = 'videoErrored';
			e.url = url;
			errorCB(e);
		};
		ValotaEngine.PWA.videoOwners[uuid].name = name;
		ValotaEngine.PWA.videoOwners[uuid].url = url;

//		ValotaEngine.PWA.videoOwners[uuid].engineElem = document.createElement('video');
		ValotaEngine.PWA.videoOwners[uuid].elem.addEventListener("ended", ValotaEngine.PWA.videoOwners[uuid].videoEndedCB);
		ValotaEngine.PWA.videoOwners[uuid].elem.addEventListener("error", ValotaEngine.PWA.videoOwners[uuid].videoErrorCB);
		ValotaEngine.PWA.videoOwners[uuid].elem.dataset.ownerUuid = uuid;
		//document.body.append(ValotaEngine.PWA.videoOwners[uuid].engineElem);
		ValotaEngine.PWA.videoOwners[uuid].elem.src = ValotaEngine.PWA.files[name].blobUrl;
		ValotaEngine.PWA.videoOwners[uuid].elem.muted = ValotaEngine.Video.getMuteStatus(uuid);
		ValotaEngine.PWA.videoOwners[uuid].elem.play().then(function () {
			playStartedCB(ValotaEngine.PWA.videoOwners[uuid].elem.duration, uuid);
			valotaPWAFS.used(name);
		}).catch(function (ev) {
			errorCB(ev, uuid);
		});
	},
	resizeVideo: function (uuid, elem, iframeRect) {
		if (ValotaEngine.PWA.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to resize video when something else is playing";
		}
	},
	stopVideo: function (uuid, elem) {
		if (typeof ValotaEngine.PWA.videoOwners[uuid] === 'undefined') {
			throw "unknown uuid"; // never played anything
		}
		if (ValotaEngine.PWA.videoOwners[uuid].elem === null) {
			throw "not running"; // played but not running
		}
		if (ValotaEngine.PWA.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to stop video when something else is playing";
		}
		ValotaEngine.PWA.videoOwners[uuid].elem.pause();
		ValotaEngine.PWA.videoOwners[uuid].elem.removeEventListener("ended", ValotaEngine.PWA.videoOwners[uuid].videoEndedCB);
		ValotaEngine.PWA.videoOwners[uuid].elem.removeEventListener("error", ValotaEngine.PWA.videoOwners[uuid].videoErrorCB);
		ValotaEngine.PWA.videoOwners[uuid].elem.src = "";
		ValotaEngine.PWA.videoOwners[uuid].elem.load();
		//document.body.removeChild(ValotaEngine.PWA.videoOwners[uuid].engineElem);
		ValotaEngine.PWA.videoOwners[uuid].elem = null;
		ValotaEngine.PWA.videoOwners[uuid].videoEndedCB = null;
		ValotaEngine.PWA.videoOwners[uuid].videoErrorCB = null;
		ValotaEngine.PWA.videoOwners[uuid].videoStartedPlayingCB = null;
		//ValotaEngine.PWA.videoOwners[uuid].elem = null;
		ValotaEngine.PWA.videoOwners[uuid].url = null;
	},
	replayVideo: function (uuid, elem, playStartedCB, errorCB) {
		if (ValotaEngine.PWA.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to replay video that is not playing";
		}
		ValotaEngine.PWA.videoOwners[uuid].elem.play().then(function () {
			playStartedCB(ValotaEngine.PWA.videoOwners[uuid].elem.duration, uuid);
			valotaPWAFS.used(ValotaEngine.PWA.videoOwners[uuid].name);
		}).catch(function (ev) {
			errorCB(ev, uuid);
		});
		ValotaEngine.PWA.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.PWA.videoOwners[uuid].videoErrorCB = errorCB;
	},
	stopVideos: function () {
		for (var uuid in ValotaEngine.PWA.videoOwners) {
			if (ValotaEngine.PWA.videoOwners[uuid].elem !== null) {
				try {
					ValotaEngine.PWA.videoOwners[uuid].videoEndedCB();
					ValotaEngine.PWA.stopVideo(uuid, ValotaEngine.PWA.videoOwners[uuid].elem);
				} catch (e) {
					// do nothing now
				}
			}
		}
	}
};

/**
 * filesystem access
 * @type 
 */
var ValotaPWAFS = function () {
	var rootDirectory = null;
	var valotaFiles = {};
	var inited = false;
	if ("storage" in navigator === false) {
		logError("OPFS file system not available " + location.href);
		return;
	}

// Store a reference to the overall root directory on loading

	navigator.storage.getDirectory().then(async (fetchedRootDirectory) => {
		rootDirectory = fetchedRootDirectory;
		getFileListAtStartup();
	});

	/**
	 * Clears the given file
	 * @param {string} filename
	 * @returns {undefined}
	 */
	function clear(filename) {
		valotaFiles[filename].loadingStart = null;
		valotaFiles[filename].sendResponse = null;
		valotaFiles[filename].blob = [];
		valotaFiles[filename].blobUrl = null;
		valotaFiles[filename].complete = false;
	}

	/**
	 * getFileListAtStartup reads the files from disk
	 *
	 * @returns {undefined}
	 */
	async function getFileListAtStartup() {
		var directoryHandle = await rootDirectory.getDirectoryHandle("videos", {create: true});
		for await (let [name, handle] of directoryHandle.entries()) {
			if (localStorage.getItem('valota_extension_file_used_' + encodeKey(name)) === null) { // probably hard reloaded the display and all the video entries in localStorage are gone, let's set it to be used now
//					localStorage.setItem('valota_extension_file_used_' + encodeKey(name), parseInt(new Date() / 1000));
// delete file as probably hard reload was done
				directoryHandle.removeEntry(name);
				console.log("removed ", name, "as not found from localStorage");
			} else {
				const file = await handle.getFile();
				valotaFiles[name] = {
					name: name,
					url: null,
					loadingStart: null,
					status: "disked",
					sendResponse: null,
					blob: [],
					complete: false,
					blobUrl: URL.createObjectURL(file),
					resolves: [],
					rejects: []
				};
//				console.log("found", name, handle);
			}
		}
		inited = true;
	}

	/**
	 * purgeCache empties the whole cache
	 *
	 * @returns {undefined}
	 */
	async function purgeCache() {
		var directoryHandle = await rootDirectory.getDirectoryHandle("videos", {create: true});
		for await (let [name, handle] of directoryHandle.entries()) {
			directoryHandle.removeEntry(name);
			localStorage.removeItem('valota_extension_file_used_' + encodeKey(name));
		}
	}

	/**
	 * listFiles lists all files
	 *
	 * @returns {undefined}
	 */
	async function listFiles() {
		console.log("[Engine PWA] listing files");

		var directoryHandle = await rootDirectory.getDirectoryHandle("videos", {create: true});
		for await (let [name, handle] of directoryHandle.entries()) {
			console.log("[Engine PWA]", name);
		}
		console.log("[Engine PWA] listing files ends");
	}

	/**
	 * Starts to load a URL
	 *
	 * @param {string} filename
	 * @param {string} url
	 * @param {function} resolve
	 * @param {function} reject
	 * @returns {undefined}
	 */
	function loadURL(filename, url, resolve, reject) {
		valotaFiles[filename] = {
			name: filename,
			url: url,
			loadingStart: new Date() / 1000,
			status: "loading",
			blob: [],
			blobUrl: null,
			complete: false,
			resolves: [resolve],
			rejects: [reject]
		};
		console.log("[Engine PWA] loading URL", valotaFiles[filename].url);
		loadURLOverXHR(filename, function (message) {
			resolveAll(message, filename);
		}, function (message) {
			rejectAll(message, filename);
		});
	}

	/**
	 * deletes a file from cache
	 * @param {string} filename
	 * @returns {undefined}
	 */
	async function deleteFromCache(filename) {
		console.warn("[Engine PWA] deleting", filename);
		var directoryHandle = await rootDirectory.getDirectoryHandle("videos", {create: true});
		directoryHandle.removeEntry(filename);
		localStorage.removeItem('valota_extension_file_used_' + encodeKey(filename));
		delete valotaFiles[filename];
	}

	/**
	 * checks whether file has been used within last 8 days and asks to be deleted if not
	 * @param {type} filename
	 * @returns {undefined}
	 */
	function checkAndDelete(filename) {
		var useTime = localStorage.getItem('valota_extension_file_used_' + encodeKey(filename));
		if (useTime === null) { // not in local storage, delete
			deleteFromCache(filename);
			return;
		}
		if ((new Date() / 1000) - parseFloat(useTime) > 691200) {
			// files accessed more than 8 days ago will be purged
			deleteFromCache(filename);
		}
	}

	/**
	 * checks the cache entries if any can be deleted due to unuse
	 * @returns {undefined}
	 */
	async function checkAndPurgeCache() {
		var directoryHandle = await rootDirectory.getDirectoryHandle("videos", {create: true});
		for await (let [name, handle] of directoryHandle.entries()) {
			checkAndDelete(name);
		}
	}

	/**
	 * encodes a string to be used as a storade key
	 * @param {type} key
	 * @returns {String}
	 */
	function encodeKey(key) {
		return encodeURI(key).toString().replace(/[.]/g, "_");
	}

	/**
	 * marks a file used at the moment this method is called
	 * @param {type} filename
	 * @returns {undefined}
	 */
	function used(filename) {
		localStorage.setItem('valota_extension_file_used_' + encodeKey(filename), parseInt(new Date() / 1000));
//		checkAndPurgeCache();
	}

	/**
	 * Takes a path string and ensures we can write a file at the end of it.
	 * @param {string} location Path of where we're going to create the file
	 * @returns
	 */
	const createDestinationVideoFileHandle = async (filename) => {
		const destinationFolder = await rootDirectory.getDirectoryHandle("videos");
		return await destinationFolder.getFileHandle(filename, {create: true});
	};
	/**
	 * Takes a path string and ensures we can write a file at the end of it.
	 * @param {string} location Path of where we're going to create the file
	 * @returns
	 */
	const getSourceFileHandle = async (filename) => {
		const destinationFolder = await rootDirectory.getDirectoryHandle("videos");
		return await destinationFolder.getFileHandle(filename, {create: false});
	};
	/**
	 * xhr loaded
	 * @param {Object} e
	 * @param {String} filename
	 * @param {function} resolve
	 * @param {function} reject
	 * @returns {undefined}
	 */
	async function xhrLoadCB(e, filename, resolve, reject) {
		console.log("[Engine PWA] xhr load", e, filename);
		if (e.target.status === 200) {

			const destinationFileHandle = await createDestinationVideoFileHandle(filename);

			// Create a writable stream for the destination file
			const destinationWritableStream = await destinationFileHandle.createWritable();

			// Write out the blob data
			try {
				await destinationWritableStream.write(e.target.response);
			} catch (e) {
				if (e.name === 'QuotaExceededError') {
					console.error("quita exceeded", e);
				} else {
					console.error("couldn't write file", filename, e);
				}
				reject({message: "xhr getFile " + filename + ": " + e.toString()});
			}
			// Mark the destination file as being closed
			await destinationWritableStream.close();
			console.log("wrote file " + filename);

			const fileHandle = await getSourceFileHandle(filename);

			const file = await fileHandle.getFile();

			valotaFiles[filename].blobUrl = URL.createObjectURL(file);
			used(filename);
			valotaFiles[filename].status = "disked";
			valotaFiles[filename].blob = [];
			resolve({status: "ok", message: "loaded", blobUrl: valotaFiles[filename].blobUrl});
			clear(filename);
		} else {
			reject({message: "xhr status " + e.target.status + " " + filename + " " + e.toString()});
//		console.warn("unable to load", e);
			clear(filename);
		}
	}

	/**
	 * start a xhr to get a file over the network
	 * @param {string} filename
	 * @param {function} resolve
	 * @param {function} reject
	 * @returns {undefined}
	 */
	function loadURLOverXHR(filename, resolve, reject) {
		// put to cache
		var xhr = new XMLHttpRequest();
		xhr.open('GET', valotaFiles[filename].url, true);
		xhr.responseType = 'arraybuffer';
		xhr.onload = (e) => {
			xhrLoadCB(e, filename, resolve, reject);
		};
		xhr.onerror = (e) => {
			reject({message: "xhr onload " + filename + ": " + e});
		};
		xhr.onerror = (e) => {
			reject({message: "xhr onload abort " + filename + ": " + e});
		};
		xhr.send();
	}

	function resolveAll(obj, filename) {
		while (valotaFiles[filename].resolves.length) {
			valotaFiles[filename].resolves.pop()(obj);
		}
		while (valotaFiles[filename].rejects.length) {
			valotaFiles[filename].rejects.pop();
		}
	}

	function rejectAll(obj, filename) {
		while (valotaFiles[filename].resolves.length) {
			valotaFiles[filename].resolves.pop();
		}
		while (valotaFiles[filename].rejects.length) {
			valotaFiles[filename].rejects.pop()(obj);
		}
	}

	return {// public interface
		/**
		 * ask for a new file to be loaded over network
		 *
		 * @param {string} filename a unique filename
		 * @param {string} url url for where to get the data for the file
		 * @param {function} sendResponse
		 */
		loadURL: async function (filename, url) {
			var n = 0;
			while (!inited && n < 5) {
				await new Promise(r => setTimeout(r, 500));
				n++;
			}
			if (!inited) {
				throw new Error('unable to init disk in sane time');
			}
			return new Promise((resolve, reject) => {
				if (typeof valotaFiles[filename] !== 'undefined') {
					if (valotaFiles[filename].loadingStart !== null) {
						console.warn("[Engine PWA] loadURL called for file already caching", filename, valotaFiles[filename]);
						valotaFiles[filename].resolves.push(resolve);
						valotaFiles[filename].rejects.push(resolve);
						return;
					}
					if (valotaFiles[filename].status === "disked") {
						resolve({status: "ok", message: "cached", blobUrl: valotaFiles[filename].blobUrl});
					}
					return;
				}
				loadURL(filename, url, resolve, reject);
			});
		},
		/**
		 * @returns {Boolean} true if ready to accept new cache task, false if not
		 */
		ready: function () {
			return rootDirectory !== null && inited === true;
		},
		clearCache: function () {
			purgeCache();
		},
		clear: function () {
		},
		list: function () {
			for (var key in valotaFiles) {
				console.log("[Engine PWA]", valotaFiles[key]);
			}
			listFiles();
		},
		used: function (filename) {
			used(filename);
		},
		deleteFromCache: function (filename) {
			deleteFromCache(filename);
		}
	};
};
