/**
 * VALOTA CONFIDENTIAL
 * __________________
 *
 * [2013] - [2016] Valota Limited
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 *
 *
 */

/* global _VALOTA_TESTING_MAX, _VALOTA_DEVICE, ValotaAndroid, ValotaEngine, _VALOTA_EXTENSION, _VALOTA_REST, _applicationData, _userName, Storage, _STATIC_CONTENT, _NO_FIRST_FETCH, _STATIC_SERVE, FLOW_SLOWNESS, _VALOTA_TIZEN, _VALOTA_ELECTRON, _VALOTA_HOME, continueRunning, _VALOTA_LOG_REST, _VALOTA_PWA */

if (typeof _PREVIEW === 'undefined') {
	_PREVIEW = false;
}
const _JS_BUILD_VERSION = '2.2.4';
const _JS_BUILD_TIME = '1739532876';
const _JS_GIT_COMMIT = '861e2969b24da6699d1596eab46957f6c87c65de';
let currentStory = -1; // current story index
let _overlayApp = null;
let _displayID = null;
if (_PREVIEW) {
	_displayID = 'PREVIEW';
}

let _DISPLAY_UPDATED = 0;
let _SHOW_TITLE = null;
let _SHOW_LOGO = null;
let _SHOW_STATS = null;
let _SHOW_NEXT = null;
let _SHOW_TITLE_CLOCK = false;
let _SHOW_STATS_CLOCK = false;

let _TIME_CHECK = 0;
let _CUR_CLOCK = '00:00';

let _READY_CALLBACK = null;

let _PREVIEW_PAUSED = false;
let _viewDistance = 1.5;

let _LAST_TIMER_EPOCH_S = Date.now() / 1000;
let _SCRIPT_START_EPOCH_MS = Date.now();
let _REQUEST_TIMER = null;

let _VALOTA_CUR_BG = 0;

let _FLOW_HANDLER = null;

let _DEFAULT_RUN_TIME = 10; // How many seconds a app runs by default
let _DEFAULT_CYCLE_TIME = 10; // How many seconds one app cycle runs by default

let _DEFAULT_CYCLES = 3; // How many cycles app tries to run by default

let _MAX_RUN_TIME = 2 * 60 * 60; // Maximum run time ( two hours )
let _MAX_CYCLE_TIME = 2 * 60 * 60; // Maximum cycle time ( two hours )

let _MAX_CYCLES = 40; // max cycles

let _SCREENSHOT_INTERVAL_S = 60 * 60 * 12; // screenshot interval for apps, should be quite a high number (once a day or so)

let _TRANSITION_TIME = 300; // Transition times in ms

let _DISPLAY_ONLINE = new Date();

let _APP_ERROR_CHECK_FREQUENCE = 60000; // milliseconds
let _APP_INACTIVE_TICKS_TO_RELOAD = 5; // 5 minutes of inactivity without a reason causes a reload
let _APP_INACTIVE_TICKS_TO_RELOAD_ALL_OK = 60; // 60 minutes of inactivity even with a reason causes a reload
let _ENGINE_ERROR = false; // engine error causes a reload
let _ENGINE_ERROR_ROUNDS = 0;

let _LATITUDE = null;
let _LONGITUDE = null;
let _PLAY_HISTORY_ENABLED = false;

let _HISTORY_TRACK = true;
let _HISTORY_INTERVAL_S = 1000 * 60 * 2; // two minutes to simulate ticks
let _HISTORY = {};


let _NOTIFICATIONS = []; // notifications for engine to show

/*
 {
 app_uuid:
 title:
 icon:
 name:
 msg:
 show_until:
 }
 */

/*
 * These values are are defined by the server, so they are commented out here
 var _VALOTA_REST = 'http://localhost/valota_rest/';
 var _VALOTA_LOG_REST = 'http://localhost/valota_log_rest/';
 var _DATA_REFRESH_TIME = 10; // seconds
 */

//Polyfill for some older browsers
Math.log2 = Math.log2 || function (x) {
	return Math.log(x) * Math.LOG2E;
};

if (!Date.now) {
	Date.now = function () {
		return new Date().getTime();
	};
}

function setLocal(name, value) {
	if (staticServe() && name === 'application_data') {
		return;
	}

	if (_PREVIEW) {
		return;
	}

	if (typeof value === 'object' || typeof value === 'array') {
		value = JSON.stringify(value);
	}
	localStorage.setItem(_LOCALSTORAGE_PREFIX + name, value);
}

function getLocal(name, def) {

	if (_PREVIEW) {
		return;
	}
	var item;
	var origItem = localStorage.getItem(_LOCALSTORAGE_PREFIX + name);

	if (origItem === null) {
		if (typeof (def) !== 'undefined') {
			return def;
		}
		return null;
	}

	try {
		item = JSON.parse(origItem);
	} catch (error) {
		item = origItem;
	}

	return item;
}

function getDeviceClass() {

	if (typeof _VALOTA_PWA !== 'undefined') {
		return ValotaEngine.PWA;
	} else if (typeof _VALOTA_DEVICE !== 'undefined') {
		return ValotaEngine.ChromeOS;
	} else if (typeof _VALOTA_EXTENSION !== 'undefined') {
		return ValotaEngine.ChromeExtension;
	} else if (typeof _VALOTA_TIZEN !== 'undefined') {
		return ValotaEngine.Tizen;
	} else if (typeof ValotaAndroid !== 'undefined') {
		return ValotaEngine.Android;
	} else if(typeof _VALOTA_ELECTRON !== 'undefined') {
		return ValotaEngine.ElectronApp;
	}

	return ValotaEngine.FileSystem;
}

function videoPlayedSeparately(){
	if(typeof _VALOTA_DEVICE !== 'undefined' || typeof _VALOTA_TIZEN !== 'undefined'){
		return true;
	}
	return false;
}

function saveUUID() {
	getDeviceClass().saveUUID(_displayID);
}

function clearUUID() {
	getDeviceClass().clearUUID();
}

// also used in extension
function makeId(length) {
	var possibleFirst = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var possible = possibleFirst + "!#%&/()=?+-_^§½<>[]{}$";

	var text = possibleFirst.charAt(Math.floor(Math.random() * possibleFirst.length));

	for (var i = 1; i < length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
}


/**
 * Simplified and unified gettext+sprintf function for javascript
 *
 * @returns {String}
 */
function _() {
// get the arguments
	var args = [];
	Array.prototype.push.apply(args, arguments);
	//if we have no arguments return an empty string
	if (args.length === 0) {
		return '';
	}

	var text = args[0];
	var loc = text.search("%s");
	var num = 1;
	while (loc !== -1) {
		var replacement = '';
		if (typeof args[num] !== 'undefined') {
			replacement = args[num];
		}

		text = text.replace("%s", replacement);
		loc = text.search("%s");
		++num;
	}

	return text;
}

/**
 * Test whether variable is defined
 *
 * @param {*} test variable to test
 * @returns {boolean} true if variable is defined
 */
function isset(test) {
	if (typeof (test) === 'undefined')
		return false;
	return true;
}

/**
 * Check whether variable is empty
 *
 * @param {*} test variable to test
 * @returns {boolean} true if variable is not empty
 */
function isEmpty(test) {
	if (typeof (test) === 'undefined' || typeof (test) === null || !test)
		return true;
	return false;
}


/**
 * Change time to seconds
 *
 * @param {number} hours
 * @param {number} mins
 * @param {number} secs
 * @returns {number}
 */
function timeToSecs(hours, mins, secs) {
	var ret = 0;
	ret += parseInt(hours) * 60;
	if (isset(mins)) {
		ret += parseInt(mins);
	}

	ret *= 60;
	if (isset(secs)) {
		ret += parseInt(secs);
	}


	return ret;
}


if (!Array.prototype.indexOf) {
	Array.prototype.indexOf = function (elt /*, from*/) {
		var len = this.length >>> 0;
		var from = Number(arguments[1]) || 0;
		from = (from < 0) ? Math.ceil(from) : Math.floor(from);
		if (from < 0)
			from += len;
		for (; from < len; from++) {
			if (from in this &&
				this[from] === elt)
				return from;
		}
		return -1;
	};
}


function init() {
	window.onerror = function (event, source, lineno, colno, error) {
		_ENGINE_ERROR = true;
		var eventString = (typeof event !== 'undefined' ? event.toString() : "undefined");
		if (typeof event !== 'undefined' && event.toString() === "[object CustomEvent]" && typeof event.detail !== 'undefined') {
			logError("CustomEvent: " + JSON.stringify(event.detail));
		} else {
			logError("window onerror start of " + source + "(" + lineno + "/" + colno + "): " + eventString + " " + (typeof error !== "undefined" ? error.toString() : "undefined"));
		}
		return false;
	};


	if (!checkCompatibility()) {
		// browser / framework doesn't support our technologies
		var errorDiv = document.createElement('div');
		errorDiv.className = 'error_cont';
		var error = document.createElement('div');
		error.className = 'error';
		error.innerHTML = _('Unfortunately your current browser version doesn\'t \n\
	seem to support enough of the HTML5 standard for our engine.');
		errorDiv.appendChild(error);
		document.getElementById('valota_container').innerHTML = '';
		document.getElementById('valota_container').appendChild(errorDiv);

		return;
	}


	if (_PREVIEW) {
		initPreview();

		chooseStage(true);
		initServiceWorker();
		return;
	}
	if (typeof _VALOTA_TESTING_MAX !== "undefined") {
		chooseStage(true);
		return;
	}

	if ("geolocation" in navigator) {
		navigator.geolocation.getCurrentPosition(function (position) {
			_LATITUDE = position.coords.latitude;
			_LONGITUDE = position.coords.longitude;
		});
	}

	checkRequest();

	if (typeof _VALOTA_EXTENSION === 'undefined') {
		// extension gets the uuid from the extension

		identifyDisplay();

		loadSavedData();

		chooseStage(true);
	} else {
		$("#valota_container").addClass('extension');
	}
	var displayStatus = getLocal('display_status');
	if (displayStatus === 'off') {
		displayOff();
	}
	var isUnpaid = getLocal('unpaid');
	if (isUnpaid) {
		unpaid();
	}
	var style = document.createElement('style');
	style.type = 'text/css';
	style.setAttribute('id', 'valota_main_css');
	var main_css = "div#claim_pin:before{content:'login to " + _VALOTA_HOME + "#claim with';}";
	if (style.styleSheet) {
		style.styleSheet.cssText = main_css;
	} else {
		style.innerHTML = main_css;
	}
	document.getElementsByTagName('head')[0].appendChild(style);

	getDeviceClass().start();

	if (!_PREVIEW) {
		setInterval(errorCheck, _APP_ERROR_CHECK_FREQUENCE);

		_LOG_START = Date.now() / 1000;

		setTimeout(sendLog, 3600000);

		if (_RESIZE_TIMEOUT) {
			clearTimeout(_RESIZE_TIMEOUT);
			_RESIZE_TIMEOUT = null;
		}

		_RESIZE_TIMEOUT = setTimeout(sendDimensions, 30000);
	}
	initServiceWorker();

	document.getElementById('enter_pin').addEventListener('mouseover', function() {showPIN()});
	document.getElementById('enter_pin_form').addEventListener('submit', function() {return false});


}

function initServiceWorker() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.register('sw.js').then(reg => {
			reg.addEventListener('updatefound', function () {
				let worker = reg.installing;
				worker.addEventListener('statechange', function () {
					if (worker.state === 'installed') {
						// Update installed, reload page
						reloadDisplay(false);
					}
				});
			});
		}).catch(registrationError => {
			console.warn('SW registration failed: ' + registrationError);
			logError('SW registration failed: ' + registrationError);
		});

	}
}

var _REPORTED_WIDTH = null;
var _REPORTED_HEIGHT = null;

function sendDimensions() {

	if (_PREVIEW || typeof _VALOTA_TESTING_MAX !== "undefined") {
		return;
	}
	var width = window.innerWidth;
	var height = window.innerHeight;
	if (width === _REPORTED_WIDTH && width === _REPORTED_HEIGHT) {
		return;
	}

	var request = {
		action: "valota_api/displays/device_dimensions"
	};
	request.displayUUID = _displayID;

	request.width = width;
	request.height = height;
	if (_LONGITUDE !== null && _LATITUDE !== null) {
		request.latitude = _LATITUDE;
		request.longitude = _LONGITUDE;

	}

	request.engine_build_version = _JS_BUILD_VERSION;
	request.engine_build_time = _JS_BUILD_TIME;
	request.engine_git_commit = _JS_GIT_COMMIT;
	request.engine_type = _JS_ENGINE_TYPE;


	$.ajax({
		type: "POST",
		url: _VALOTA_REST,
		data: request,
		success: function (data) {
			_REPORTED_WIDTH = width;
			_REPORTED_HEIGHT = height;
		},
		error: function (xhr, textStatus, errorThrown) {
			console.warn('Server responded with an error code. ' + textStatus + ' ' + errorThrown, xhr);
		},
		dataType: "json"
	});
}

function showPIN(reallyShow) {
	if ((typeof _VALOTA_EXTENSION !== 'undefined' || typeof _VALOTA_ELECTRON !== 'undefined') && typeof reallyShow === 'undefined') {
		return;
	}
	$('#show_pin_button').remove();
	document.getElementById('claim_pin').style.display = "none";
	document.getElementById('qrcode').style.display = "none";
	document.getElementById('pin_keyboard').style.display = "unset";
}

function displayOff() {
	setLocal('display_status', 'off');
	if (false) {
		//TODO This seems unfinished
		document.getElementById('owner_logo_off').style.backgroundImage = "url('" + _ownerLogo + "')";

	} else {
		document.getElementById('owner_logo_off').style.backgroundImage = "url('lib/valotalive_logo_2019.svg')";
	}
	document.getElementById('display_name_off').innerHTML = _displayName;
	document.getElementById('display_off').style.display = 'flex';
	ValotaEngine.Video.displayOff();
}

function displayOn() {
	setLocal('display_status', 'on');
	document.getElementById('display_off').style.display = 'none';
	ValotaEngine.Video.displayOn();
}

function unpaid() {
	setLocal('unpaid', 1);

	document.body.classList.add('unpaid');
}

function paid() {
	setLocal('unpaid', 0);
	document.body.classList.remove('unpaid');
}

var _RESIZE_TIMEOUT = null;

window.onresize = function () {

	if (_RESIZE_TIMEOUT) {
		clearTimeout(_RESIZE_TIMEOUT);
		_RESIZE_TIMEOUT = null;
	}
	_RESIZE_TIMEOUT = setTimeout(sendDimensions, 15000);
};

window.onunload = function () {
	if (Date.now() - _SCRIPT_START_EPOCH_MS > 10 * 60 * 1000) { // save log only if app has run 10mins
		// save to summary
		saveLog();
	}

	if (_HISTORY_TRACK) {
		saveHistory();
	}

};


function getYMDDate(d) {
	return d.getUTCFullYear() * 10000 + ((d.getUTCMonth() + 1) * 100) + d.getUTCDate();
}

function saveHistory() {

	let curTime = new Date(Date.now() + _TIME_CHECK);

	let today = getYMDDate(curTime);


	if (!(today in _HISTORY)) {
		_HISTORY[today] = {};
	}


	if (Array.isArray(_applicationData)) {
		for (var i = 0; i < _applicationData.length; ++i) {
			if (!(_applicationData[i].uuid in _HISTORY[today])) {
				_HISTORY[today][_applicationData[i].uuid] = 1;
			} else {
				++_HISTORY[today][_applicationData[i].uuid];
			}
		}
	}


	setLocal('history', JSON.stringify(_HISTORY));

}

function sendHistory() {

	let curTime = new Date(Date.now() + _TIME_CHECK);

	if (curTime.getUTCHours() < 3) {
		//don't send right after midnight
		return;
	}

	curTime.setUTCDate(curTime.getUTCDate() - 1);

	let send = [];


	let dateToSend = getYMDDate(curTime);

	while (dateToSend in _HISTORY) {

		send.push({date: dateToSend, data: _HISTORY[dateToSend]});

		curTime.setUTCDate(curTime.getUTCDate() - 1);
		dateToSend = getYMDDate(curTime);
	}

	if (send.length) {

		let request = {
			action: "valota_api/displays/history",
			displayUUID: _displayID,
			days: send
		};


		$.ajax({
			type: "POST",
			url: _VALOTA_REST,
			data: request,
			success: function (data) {
				if ("status" in data && data.status === 'ok') {

					for (let i in send) {
						if (send[i].date in _HISTORY) {
							delete _HISTORY[send[i].date];
						}
					}

					setLocal('history', JSON.stringify(_HISTORY));
				} else {
					console.warn('Failed to save history. ' + data);
				}

			},
			error: function (xhr, textStatus, errorThrown) {
				console.warn('Server responded with an error code. ' + textStatus + ' ' + errorThrown, xhr);
			},
			dataType: "json"
		});


	}
}

function saveLog() {
	if (_PREVIEW) {
		return;
	}
	if (!_PLAY_HISTORY_ENABLED) {
		return;
	}

	if (_FLOW_HANDLER) {

		_UNSENT_LOGS.push(_FLOW_HANDLER.reportLog());

	}

	setLocal('play_history', JSON.stringify(_UNSENT_LOGS));


}


var _LOG_START, _LOG_END;
var _UNSENT_LOGS = [];

function sendLog() {
	if (_PREVIEW) {
		return;
	}

	if (!_PLAY_HISTORY_ENABLED) {
		_UNSENT_LOGS = [];
		localStorage.removeItem(_LOCALSTORAGE_PREFIX + 'play_history');
		return;
	}

	if (typeof _VALOTA_TESTING_MAX !== 'undefined') {
		console.log("[Engine Test]" + " logs ");
		console.log(_FLOW_HANDLER.reportLog());
		return;
	}

	// log hourly
	setTimeout(sendLog, 3600000);

	if (_FLOW_HANDLER) {
		_UNSENT_LOGS.push(_FLOW_HANDLER.reportLog());
	}

	if (_UNSENT_LOGS.length === 0) {
		return;
	}

	var request = {
		action: "valota_api/log/display_play_history"
	};
	request.displayUUID = _displayID;

	var log_length = _UNSENT_LOGS.length;

	request.logs = _UNSENT_LOGS;
	request.deviceTime = Math.round(Date.now() / 1000);


	$.ajax({
		type: "POST",
		url: _VALOTA_REST,
		data: request,
		success: function () {

			if (_UNSENT_LOGS.length === log_length) {
				_UNSENT_LOGS = [];
			} else {
				_UNSENT_LOGS.splice(0, log_length);
			}

			setLocal('play_history', JSON.stringify(_UNSENT_LOGS));

		},
		error: function (xhr, textStatus, errorThrown) {
			console.warn('Server responded with an error code. ' + textStatus + ' ' + errorThrown, xhr);
		},
		dataType: "json"
	});
}

function closePreview() {
	if (parent) {
		if (parent.EditCustomApp) {
			parent.EditCustomApp.returnFullscreen();
		}
		if (parent.CreateCustomApp) {
			parent.CreateCustomApp.returnFullscreen();
		}

		if (parent.Displays) {
			parent.Displays.returnPreviewFullScreen();
		}
	}

}

function initPreview() {
	if (!_PREVIEW) {
		return;
	}
	// some preview functions and event handlers
	document.body.className = 'preview';
	document.body.onclick = closePreview;
	document.getElementById('valota_container').className = 'preview';

	var preview_div = document.createElement('div');
	preview_div.id = 'preview_ontop';
	preview_div.innerHTML = 'This is a preview. <br><small>Data might not be up to date, videos won\'t work and some apps may display example data.</small>';
	document.body.appendChild(preview_div);


	window.addEventListener("message", receiveMessage, false);

	// do we have an overlay app?
	for (var i = 0; i < _applicationData.length; i++) {
		if (_applicationData[i].isOverlay) {
			_overlayApp = _applicationData[i].uuid;
			break;
		}
	}

	function receiveMessage(event) {
		var resp = JSON.parse(event.data);
		var refresh_layout = false;
		var refresh_palette = false;

		switch (resp.action) {
			case 'stats':
				_SHOW_STATS = resp.value ? true : false;
				refresh_layout = true;
				break;

			case 'title':
				_SHOW_TITLE = resp.value ? true : false;
				refresh_layout = true;
				break;
			case 'logo':
				_SHOW_LOGO = resp.value ? true : false;
				refresh_layout = true;
				break;
			case 'next':
				_SHOW_NEXT = resp.value ? true : false;
				refresh_layout = true;
				break;

			case 'play':
				console.log('play');
				_PREVIEW_PAUSED = false;
				break;

			case 'pause':
				console.log('pause');
				_PREVIEW_PAUSED = true;
				break;

			case 'cycle':
				console.log('cycle');
				if(_FLOW_HANDLER) {

					_FLOW_HANDLER.cycleCurrent();
				}
				break;

			case 'nextApp':
				console.log('nextApp');
				if(_FLOW_HANDLER) {
					_FLOW_HANDLER.resetCounters();
					_FLOW_HANDLER.playNextAppOrdered();
				}

				break;

			case 'owner':
				if (resp.value) {
					_displayUser = _userName;
				} else {
					_displayUser = '';
				}
				refresh_layout = true;
				break;

			case 'view_distance':
				_viewDistance = parseFloat(resp.value);
				refresh_palette = true;
				break;

			case 'title_clock':
				_SHOW_TITLE_CLOCK = resp.value ? JSON.parse(resp.value) : false;
				refresh_layout = true;
				break;
			case 'stats_clock':
				_SHOW_STATS_CLOCK = resp.value ? JSON.parse(resp.value) : false;
				refresh_layout = true;
				break;

		}

		if (refresh_layout) {
			engineLayout();
		}
		if (refresh_palette) {
			for (let i = 0; i < _applicationData.length; ++i) {
				if (_applicationData[i].uuid === _overlayApp && videoPlayedSeparately()) {
					getDeviceClass().overlayAppChangedPalette(_application[i].palette);
				} else {
					runFunction(_applicationData[i].container.contentWindow.ValotaPaletteHasChanged, 'ValotaPaletteHasChanged', _applicationData[i].uuid);
				}
			}
		}
	}


}

/**
 * Clears the local storage, but retains display id and csrfToken
 */
Storage.prototype._clear = Storage.prototype.clear;
Storage.prototype.clear = function () {
	var csrfToken = localStorage.getItem('csrfToken');
	this._clear();
	if (csrfToken !== null) {
		localStorage.setItem('csrfToken', csrfToken);
	}
	if (typeof _displayID !== "undefined" && _displayID !== null) {
		setLocal('display', _displayID);
	}
};

/**
 * Check if engine's applications have errors and reload them if they have
 *
 */
function errorCheck() {
	if (typeof _VALOTA_TESTING_MAX !== 'undefined') {
		return;
	}
	if (!continueRunning) {
		// not the first instance, we need to stop
		return;
	}
	//TODO: Make sure engine or apps don't go into a reload loop!!

	var time_epoch_s = Date.now() / 1000;
	// Engine errors cause an instant reload and clearing of application cache
	if (_ENGINE_ERROR === true) {
		localStorage.clear();
		reloadDisplay(true);
	}

	var one_is_ready = false;
	for (let i = 0; i < _applicationData.length; ++i) {
		//Should we reload the app?
		var reload_app = false;
		// check if the app is not ready?
		if (_applicationData[i].ready === false) {
			// add to inactivity
			++_applicationData[i].inactiveFor;

		} else {
			if (_overlayApp !== _applicationData[i].uuid) {
				one_is_ready = true;
			}
			// reset inactivity counter
			_applicationData[i].inactiveFor = 0;
		}

		// if app has been inactive longer then inactivity limit
		if (_applicationData[i].inactiveFor > _APP_INACTIVE_TICKS_TO_RELOAD) {

			// ask if all is ok with the app
			var all_ok = runFunction(_applicationData[i].container.contentWindow.ValotaAllOk, 'ValotaAllOk', _applicationData[i].uuid);
			if (!all_ok) {
				// all is not ok so lets reload
				reload_app = true;
			} else {
				// all is ok, but has it been ok for too long??
				//if (_applicationData[i].inactiveFor > _APP_INACTIVE_TICKS_TO_RELOAD_ALL_OK) {
				//	reload_app = true;
				//}
			}
		}


		// has app reported errors since last error check
		if (_applicationData[i].errorsSinceCheck > 0) {
			// reload the app if there are errors
			reload_app = true;
		}


		//Do we still want to reload app?
		if (reload_app) {


			var in_5 = time_epoch_s - 300;

			if (_applicationData[i].reloads.length > 0) {

				// have we loaded this app within 5 mins already
				if (_applicationData[i].reloads[0] > in_5) {
					reload_app = false;
				}


			}
			if (reload_app) {


				//reset error numbers
				_applicationData[i].errorsSinceCheck = 0;
				_applicationData[i].cleanErrorChecks = 0;
				_applicationData[i].inactiveFor = 0;

				//add new error time
				_applicationData[i].reloads.unshift(Date.now() / 1000);

				// Every second reload should be a hard reload
				var hard_reload = _applicationData[i].reloads.length % 2 === 0 ? true : false;

				// app is not ready
				_applicationData[i].ready = false;
				try {
					ValotaEngine.Video.stopVideosByApp(_applicationData[i].uuid);
				} catch (e) {
					logError("reload app video stop errored " + e.toString(), _applicationData[i].uuid, 'warning');
				}

				console.log('[Engine] Reloading app ' + _applicationData[i].name);
				if (_overlayApp === _applicationData[i].uuid) {
					document.getElementById('overlay').removeChild(_applicationData[i].container);
				} else {
					document.getElementById('valota_app_container').removeChild(_applicationData[i].container);
				}
				//reload app iframe and hope for the best
				var frame = document.createElement('iframe');
				frame.src = _applicationData[i].url + (isset(_applicationData[i].index_time) && _applicationData[i].index_time ? "?" + _applicationData[i].index_time : "");
				frame.allow = "autoplay";
				frame.sandbox.add('allow-scripts');
				frame.sandbox.add('allow-same-origin');
				frame.sandbox.add('allow-forms');
				//frame.sandbox = 'allow-scripts allow-same-origin allow-forms';
				frame.valotaAppId = _applicationData[i].uuid;
				frame.className = 'inactive';
				if (_overlayApp === _applicationData[i].uuid) {
					document.getElementById('overlay').appendChild(frame);
				} else {
					document.getElementById('valota_app_container').appendChild(frame);
				}
				if (!_PREVIEW) {
					frame.contentWindow.onerror = function (f, z) {
						return function (event, source, lineno, colno, error) {

							errorOn(f.valotaAppId);
							var eventString = (typeof event !== 'undefined' ? event.toString() : "undefined");
							if (typeof event !== 'undefined' && event.toString() === "[object CustomEvent]" && typeof event.detail !== 'undefined') {
								logError("CustomEvent: " + JSON.stringify(event.detail));
							} else {
								logError("frame onerror for " + _applicationData[z].uuid + " start of " + source + "(" + lineno + "/" + colno + "): " + eventString + " " + (typeof error !== "undefined" ? error.toString() : "undefined"));
							}
							return false;
						};
					}(frame, i);

				}
				_applicationData[i].container = frame;
			} else {
				console.log('[Engine] App ' + _applicationData[i].name + ' needed reloading but was reloaded too recently');
			}

		} else {
			++_applicationData[i].cleanErrorChecks;
		}
	}

	//at least one is ready, why isn't valota_container the current window??
	if (one_is_ready && _CURRENT_WINDOW !== 'valota_container') {
		//five error rounds same things, reload engine
		if (_ENGINE_ERROR_ROUNDS >= 5) {
			localStorage.clear();
			reloadDisplay(true);
		}
		++_ENGINE_ERROR_ROUNDS;
	} else {
		_ENGINE_ERROR_ROUNDS = 0;
	}

}

function chooseStage(init) {

	if (!continueRunning) {
		// not the first instance, we need to stop
		return;
	}

	// we have some data, lets attempt to crunch it
	if (typeof _applicationData !== 'undefined') {
		loadApplicationData();
	}

	// resize engine
	engineLayout();

	waitForContent(init);


}

function waitForContent(init) {
	if (typeof init === 'undefined') {
		init = false;
	}

	if (_ownerLogo) {
		//document.getElementById('owner_logo').style.backgroundImage = "url(" + _ownerLogo + ")";
	}
	document.getElementById('claimed_date').innerHTML = _displayDate;
	document.getElementById('display_name').innerHTML = _displayName;

	if (init) {
		// lets add a one second delay for the first time we start waiting for content
		setTimeout(function () {
			// but only if current window is "working", we don't want to hide more important windows
			if (_CURRENT_WINDOW === 'working') {
				showWindow('waiting_for_content');
			}
		}, 1000);
	} else {
		showWindow('waiting_for_content');
	}


	if (doFetch() && (typeof _NO_FIRST_FETCH === 'undefined' || !_NO_FIRST_FETCH)) {
		if (ValotaEngine.WebSocket.supported() && _WEBSOCKET) {
			ValotaEngine.WebSocket.connect();
		} else {
			fetchData();
		}
	}
	setDisplayStarted();
}

function doFetch() {
	return (typeof _STATIC_CONTENT === 'undefined' || !_STATIC_CONTENT) && !_PREVIEW
}

function cacheFonts(num) {
	cacheFont(num, 'bodyFont');
	cacheFont(num, 'titleFont');
}

function cacheFont(num, which) {
	if (isEmpty(_applicationData[num].palette) || isEmpty(_applicationData[num].palette.palette) || isEmpty(_applicationData[num].palette.palette[which])) {
		return;
	}
	$.getJSON(_VALOTA_REST + "?action=valota_api/fonts/get_font_css&font=" + _applicationData[num].palette.palette[which], function (data) {
		if (!data.hasOwnProperty('response')) {
			console.warn("loading font failed " + _applicationData[num].palette.palette[which] + ": " + data);
			return;
		}
		if (data.response.data.css !== "") {
			// inject this css to the main document
			if (document.getElementById('valota_font_' + _applicationData[num].palette.palette[which] + '_css') !== null) {
				return;
			}
			let style = document.createElement('style');
			style.type = 'text/css';
			style.nonce = document.getElementById('engine_init_function').nonce;
			style.setAttribute('id', 'valota_font_' + _applicationData[num].palette.palette[which] + '_css');
			if (style.styleSheet) {
				style.styleSheet.cssText = data.response.data.css;
			} else {
				style.innerHTML = data.response.data.css;
			}
			document.getElementsByTagName('head')[0].appendChild(style);
		}
	}).fail(function (data) {
		console.warn("loading font failed " + _applicationData[num].palette.palette[which] + ": " + data);
	});
}

function runPendingFunctions(rnd) {

	let unfinishedBusiness = false;
	for (let i = 0; i < _applicationData.length; ++i) {
		if (_applicationData[i].changesPending.length === 0) {
			continue;
		}
		if (rnd >= 4 || (_applicationData[i].isOverlay&&videoPlayedSeparately()) || ('ValotaAllOk' in _applicationData[i].container.contentWindow && runFunction(_applicationData[i].container.contentWindow.ValotaAllOk, 'ValotaAllOk', _applicationData[i].uuid))) {
			if(_applicationData[i].isOverlay&&videoPlayedSeparately()){
				for (let j = 0; j < _applicationData[i].changesPending.length; j++) {
					switch(_applicationData[i].changesPending[j]){
						case 'ValotaPaletteHasChanged':
							getDeviceClass().overlayAppChangedPalette(_applicationData[i].palette);
							break;
						case 'ValotaCustomsHaveChanged':
							getDeviceClass().overlayAppChangedCustoms(_applicationData[i].customs);
							break;
						case 'ValotaSourceHasChanged':
							getDeviceClass().overlayAppChangedSource(_applicationData[i].source);
							break;
						default:
							logError("Unknown function pending for overlay "+_applicationData[i].changesPending[j]);
							break;
					}
				}
				_applicationData[i].changesPending = [];
			}
			else{
				for (let j = 0; j < _applicationData[i].changesPending.length; j++) {
					runFunction(_applicationData[i].container.contentWindow[_applicationData[i].changesPending[j]], _applicationData[i].changesPending[j], _applicationData[i].uuid);
				}
				_applicationData[i].changesPending = [];
			}
		} else {
			unfinishedBusiness = true;
		}

	}
	if (unfinishedBusiness && rnd < 4) {
		setTimeout(function () {
			runPendingFunctions(rnd + 1);
		}, rnd * 1000);
	}
}

function loadFonts(num, callback) {
	var n = 2;

	var temp = function (index) {
		if (isEmpty(_applicationData[num].palette) || isEmpty(_applicationData[num].palette.palette) || isEmpty(_applicationData[num].palette.palette[index])) {
			n--;
			if (n === 0) {
				callback(num);
			}
			return;
		}
		if (_applicationData[num].container.contentDocument.getElementById('valota_font_' + _applicationData[num].palette.palette[index] + '_css') !== null) {
			n--;
			if (n === 0) {
				callback(num);
			}
			return;
		}
		$.getJSON(_VALOTA_REST + "?action=valota_api/fonts/get_font_css&font=" + _applicationData[num].palette.palette[index], function (data) {
			if (!data.hasOwnProperty('response')) {
				console.warn("loading font failed " + _applicationData[num].palette.palette[index] + ": " + data);
				n--;
				if (n === 0) {
					callback(num);
				}
				return;
			}
			if (data.response.data.css !== "") {
				// inject this css to the iframe
				var style = document.createElement('style');
				style.type = 'text/css';
				style.setAttribute('id', 'valota_font_' + _applicationData[num].palette.palette[index] + '_css');
				if (style.styleSheet) {
					style.styleSheet.cssText = data.response.data.css;
				} else {
					style.innerHTML = data.response.data.css;
				}
				_applicationData[num].container.contentDocument.getElementsByTagName('head')[0].appendChild(style);
			}
			n--;
			if (n === 0) {
				callback(num);
			}
		}).fail(function (data) {
			console.warn("loading font failed " + _applicationData[num].palette.palette[index] + ": " + data);
			n--;
			if (n === 0) {
				callback(num);
			}
		});
	};
	temp('bodyFont');
	temp('titleFont');
}

function loadApplicationData() {
	for (var i = 0; i < _applicationData.length; ++i) {
		cacheFonts(i);
		loadApplication(i);
	}
}

function loadApplication(num) {
	_applicationData[num].ready = false;
	_applicationData[num].latestContent = -1;
	_applicationData[num].changesPending = [];
	if (_applicationData[num].uuid === _overlayApp && videoPlayedSeparately()) {
		_applicationData[num].isOverlay = true;
		getDeviceClass().playOverlayApp(_applicationData[num]);
		_applicationData[num].inactiveFor = NaN; // set to NaN to not reload from here
		return;
	}
//errors variables
	_applicationData[num].errorsSinceCheck = 0;
	_applicationData[num].cleanErrorChecks = 0;
	_applicationData[num].reloads = [];
	_applicationData[num].inactiveFor = 0;

	_applicationData[num].playNext = null;
	if (typeof (_applicationData[num].runTime) === 'undefined') {
		_applicationData[num].runTime = _DEFAULT_RUN_TIME;
	}
	if (typeof (_applicationData[num].cycleTime) === 'undefined') {
		_applicationData[num].cycleTime = _DEFAULT_CYCLE_TIME;
	}

	if (typeof (_applicationData[num].preferredCycles) === 'undefined') {
		_applicationData[num].preferredCycles = _DEFAULT_CYCLES;
	}
	var frame = document.createElement('iframe');
	frame.src = _applicationData[num].url + (isset(_applicationData[num].index_time) && _applicationData[num].index_time ? "?" + _applicationData[num].index_time : "");
	frame.allow = "autoplay";
	frame.sandbox.add('allow-scripts');
	frame.sandbox.add('allow-same-origin');
	frame.sandbox.add('allow-forms');
//	frame.sandbox = 'allow-scripts allow-same-origin allow-forms';
	frame.valotaAppId = _applicationData[num].uuid;
	frame.className = 'inactive';
	if (_applicationData[num].uuid === _overlayApp) {
		document.getElementById('overlay').appendChild(frame);
		_applicationData[num].isOverlay = true;
	} else {
		document.getElementById('valota_app_container').appendChild(frame);
	}
	if (!_PREVIEW) {
		frame.contentWindow.onerror = function (event, source, lineno, colno, error) {

			errorOn(frame.valotaAppId);
			var eventString = (typeof event !== 'undefined' ? event.toString() : "undefined");
			if (typeof event !== 'undefined' && event.toString() === "[object CustomEvent]" && typeof event.detail !== 'undefined') {
				logError("CustomEvent: " + JSON.stringify(event.detail));
			} else {
				logError("frame onerror for " + _applicationData[num].uuid + " start of " + source + "(" + lineno + "/" + colno + "): " + eventString + " " + (typeof error !== "undefined" ? error.toString() : "undefined"));
			}
			return false;
		};
		frame.contentWindow.onkeydown = handleKey;
	}

	_applicationData[num].container = frame;

	preloadBgs(num);
}

function errorOn(appUUID) {
	console.warn("[Engine] error in", appUUID);
	var num = getStoryLoc(appUUID);

	if (num === -1) {
		return;
	}
	console.warn("[Engine] error in meaning", num);

	++_applicationData[num].errorsSinceCheck;
	_applicationData[num].ready = false;
	try {
		ValotaEngine.Video.stopVideosByApp(appUUID);
	} catch (e) {
		logError("errorOn video stop errored " + e.toString(), appUUID, 'warning');
	}
	_applicationData[num].notReadyCalled = true;
	if (_overlayApp === _applicationData[num].uuid) {
		stopOverlay(num);
	}
	if (currentStory === num) {
		_applicationData[currentStory].container.className = 'inactive';
		currentStory = -1;
		_STARTING_PLAY = false;
		runStories();
	}
}

var _PREVIOUS_100_ERRORS = [];

function logError(msg, appUUID, severity) {
	if (_PREVIEW || (typeof _VALOTA_TESTING_MAX !== 'undefined')) {
		return;
	}
	if (typeof severity !== "string") {
		severity = "error";
	}
	var request = {
		action: "valota_api/log/log",
		appUUID: appUUID,
		displayUUID: _displayID,
		message: msg,
		severity: severity
	};

	if (isset(appUUID)) {
		request.appUUID = appUUID;
		var num = getStoryLoc(appUUID);

		console.error('[Error from ' + _applicationData[num].name + '] ' + msg);
		_PREVIOUS_100_ERRORS.push({app_uuid: appUUID, app_name: _applicationData[num].name, msg: msg});
	} else {
		console.error('[Error from engine] ' + msg);
		_PREVIOUS_100_ERRORS.push({app_uuid: false, app_name: '', msg: msg});
	}

	if (_PREVIOUS_100_ERRORS.length > 100) {
		_PREVIOUS_100_ERRORS.pop();
	}

	$.ajax({
		type: "POST",
		url: _VALOTA_LOG_REST,
		data: request,
		dataType: "json"
	}).done(function () {

		console.log('[Engine log] Error sent to the server');

	}).fail(function (xhr, st, err) {
		var mes = '[Engine log] Failed to send log: ' + err + ' (' + st + ')';
		console.error(mes);
	});


}

function unloadApplication(num) {
	removeApplication(num);
}

function removeApplication(num) {
	ValotaEngine.Video.stopVideosByApp(_applicationData[num].uuid);
	_applicationData[num].container.parentNode.removeChild(_applicationData[num].container);
	_applicationData.splice(num, 1);
	if (currentStory === num) {
		currentStory = -1;
	}
	if (_flow.config.uuid === 'local') {
		generateLocalFlow();
		reFlow();
	}
	setLocal('application_data', _applicationData);
}

var _REQUEST_APP = null;

/**
 * remove search part from the url and save supported keys to variables
 *
 * @returns {undefined}
 */
function checkRequest() {
	if (location.search) {
		var get_data = location.search.substr(1).split("&");
		if (!staticServe()) {
			history.pushState("", "", location.href.split("?")[0]);
		}
		for (var i = 0; i < get_data.length; ++i) {
			var req = get_data[i].split("=");
			if (req[0] === "u") {
				_REQUEST_APP = req[1];
			}
		}
	}
}


/**
 * Returns whether this display is defined as a static serve display
 *
 * @returns {Boolean}
 */
function staticServe() {
	if (typeof _STATIC_SERVE !== 'undefined' && _STATIC_SERVE) {
		return true;
	}
	return false;
}
/**
 * Check whether there should be an emergency message on and display/hide it accordingly
 */
function checkEmergency() {
	let emergency_elem = document.getElementById('valota_emergency');
	if (getLocal('emergency', null)) {
		emergency_elem.innerHTML = getLocal('emergency');
		let length = $(emergency_elem).text().length;
		let area = window.innerWidth * window.innerHeight;
		let fontSize = 0.7 * Math.sqrt(area / length);
		emergency_elem.style.fontSize = fontSize + "px";
		emergency_elem.style.display = "flex";
		ValotaEngine.Video.emergency();
		if (videoPlayedSeparately()) {
			getDeviceClass().emergency();
		}
	} else {
		emergency_elem.html = "";
		emergency_elem.style.display = "none";
		ValotaEngine.Video.emergencyOver();
		if (videoPlayedSeparately()) {
			getDeviceClass().emergencyOver();
		}
	}
}

/**
 * Load previously saved data from the localStorage
 *
 * @returns {undefined}
 */
function loadSavedData() {

	_displayName = getLocal('display_name');
	_displayUser = getLocal('display_user');
	_viewDistance = getLocal('view_distance');
	_displayDate = getLocal('claim_date');
	_ownerLogo = getLocal('owner_logo');
	_flow = getLocal('flow');
	_DISPLAY_UPDATED = getLocal('display_updated', 0);
	_SHOW_STATS = getLocal('show_stats', false);
	_SHOW_TITLE = getLocal('show_title', false);
	_SHOW_LOGO = getLocal('show_logo', true);
	_SHOW_NEXT = getLocal('show_next', false);
	_SHOW_TITLE_CLOCK = getLocal('show_title_clock', false);
	_SHOW_STATS_CLOCK = getLocal('show_stats_clock', false);
	_overlayApp = getLocal('overlay_app');

	checkEmergency();

	if (_HISTORY_TRACK) {
		_HISTORY = getLocal('history', {});

		setInterval(saveHistory, _HISTORY_INTERVAL_S); // history interval
		setInterval(sendHistory, 60 * 60 * 1000); // once an hour

	}

	_NODE_SERVERS = getLocal('node_servers', false);
	_WEBSOCKET = (_NODE_SERVERS && Array.isArray(_NODE_SERVERS) && _NODE_SERVERS.length > 0);
	if (_WEBSOCKET) {
		ValotaEngine.WebSocket.setNewNodeList(_NODE_SERVERS);
	}

	if (_PLAY_HISTORY_ENABLED) {
		_UNSENT_LOGS = getLocal('play_history', []);
	} else {
		_UNSENT_LOGS = [];
		localStorage.removeItem(_LOCALSTORAGE_PREFIX + 'play_history');
	}


	if (typeof _applicationData !== "undefined") {

		setLocal('application_data', _applicationData);

		// we have a single app served for now, reset the flow
		_flow = null;
		setLocal('flow', null);

	} else if (getLocal('application_data') !== null) {
		_applicationData = getLocal('application_data');
	}

	// make sure nothing is ready yet
	if (typeof _applicationData !== "undefined" && _applicationData) {
		for (var i = 0; i < _applicationData.length; ++i) {
			_applicationData[i].ready = false;
		}

	} else {
		_applicationData = [];
	}
//	ValotaEngine.loadLogos(); // for when recalculation animation is enabled
}


/**
 * Attempt to identify the display
 *
 * @returns {undefined}
 */
function identifyDisplay() {
	_displayID = getLocal('display');
	if (_displayID === null) {
		_displayID = makeId(32);
		setLocal('display', _displayID);
	}

}

/**
 * Does the display have apps
 *
 * @returns {Boolean}
 */
function hasApps() {
	if (typeof _applicationData !== "undefined" && _applicationData.length) {
		return true;
	}
	return false;
}

/**
 * Does the display have a flow
 *
 * @returns {Boolean}
 */
function hasFlow() {
	if (typeof _flow !== "undefined" && _flow) {
		return true;
	}
	return false;

}

/**
 *
 * @param {type} deviceId
 * @returns {undefined}
 */
function sendMyDeviceID(deviceId) {
	if (typeof deviceId !== 'string') {
		return;
	}
	var request = {
		action: "valota_api/displays/set_device_id",
		deviceID: deviceId
	};
	request.displayUUID = _displayID;
	$.ajax({
		type: "POST",
		url: _VALOTA_REST,
		data: request,
		success: function (data) {
			if (typeof data.response !== 'undefined' && typeof data.response.old_display !== 'undefined' && data.response.old_display) {
				console.warn("[Engine Device ID] new uuid");
				// this player already had a display				
				_displayID = data.response.old_display;
				setLocal('display', _displayID);
				_DISPLAY_UPDATED = null;
				saveUUID();
				fetchData(true);
			}
		},
		error: function (xhr, textStatus, errorThrown) {
			console.warn('Server responded with an error code. ' + textStatus + ' ' + errorThrown, xhr);
		},
		dataType: "json"
	});
}

/**
 *
 * @param {type} btAddress
 * @returns {undefined}
 */
function sendMyBTAddress(btAddress) {
	if (typeof btAddress === 'undefined' || btAddress === null) {
		return;
	}
	var request = {
		action: "valota_api/displays/set_bt_address",
		btAddress: btAddress
	};
	request.displayUUID = _displayID;
	$.ajax({
		type: "POST",
		url: _VALOTA_REST,
		data: request,
		success: function (data) {
			if (typeof data.response !== 'undefined' && typeof data.response.old_display !== 'undefined' && data.response.old_display) {
				// this bt address already used
				console.error("[Engine] BT conflict");
			}
		},
		error: function (xhr, textStatus, errorThrown) {
			console.warn('Server responded with an error code. ' + textStatus + ' ' + errorThrown, xhr);
		},
		dataType: "json"
	});
}

var _FETCH_FAILS = 0;
var _FETCH_SUCCESSFUL = 0;
var _FETCH_FAILS_IN_ROW = 0;
var _FETCH_FAIL_START = null;
var _FETCH_FAILS_REASON = null;
var _FETCH_LAST_SUCCESSFUL_TIME_MS = 0;

function failedFetch(mes) {
	console.warn(mes);
	if (_FETCH_FAILS_IN_ROW === 0) {
		_FETCH_FAIL_START = new Date();
	}
	_FETCH_FAILS_REASON = mes;
	++_FETCH_FAILS;
	++_FETCH_FAILS_IN_ROW;
	setStatus();
}

function _successfulFetch() {
	_FETCH_FAILS_IN_ROW = 0;
	_FETCH_FAIL_START = null;
	++_FETCH_SUCCESSFUL;
	_FETCH_LAST_SUCCESSFUL_TIME_MS = Date.now();
	setStatus();

}

function setStatus() {
	let statsEl =$('#display_stats');
	let statsReasonEl =$('#stats_offline_reason');
	if (_IS_OFFLINE  || (!ValotaEngine.WebSocket.connected() && _FETCH_FAILS_IN_ROW > 0)) {
		if (_IS_OFFLINE || _FETCH_FAILS_IN_ROW > 2) {
			statsEl.toggleClass('error', true);
			statsEl.toggleClass('warning', false);
			statsEl.toggleClass('play', false);
		} else {
			statsEl.toggleClass('error', false);
			statsEl.toggleClass('warning', true);
			statsEl.toggleClass('play', false);
		}


		statsReasonEl.toggleClass('hidden', false);

		statsReasonEl.html(_FETCH_FAILS_REASON);

	} else {
		statsEl.toggleClass('warning', false);
		statsEl.toggleClass('error', false);
		statsEl.toggleClass('play', true);
		statsReasonEl.toggleClass('hidden', true);
	}

	formatSmallStats();

}

/**
 * Generate / refresh the flow of apps
 */
function reFlow() {
	if (!continueRunning) {
		showWindow('not_unique');
	}
	if (_FLOW_TIMEOUT) {
		console.log('[Engine] remove flow interval');
		clearInterval(_FLOW_TIMEOUT);
		_FLOW_TIMEOUT = null;
	}

	if (_FLOW_HANDLER === null || _FLOW_HANDLER._uuid !== _flow.config.uuid) {
		// our flow is totally new
		if (_FLOW_HANDLER) {
			saveLog();
		}
		ValotaEngine.Video.stopAllVideos();
		_FLOW_HANDLER = new ValotaEngine.FlowHandler(_flow.config, 0);
		_FLOW_HANDLER.loadContents(_flow.contents);
	} else {
		// old flow...
		// update flow only if update id changes
		if (_flow.config.updateId !== _FLOW_HANDLER._updateId) {
			saveLog();

			_FLOW_HANDLER._name = _flow.config.name;
			_FLOW_HANDLER._updateId = _flow.config.updateId;
			_FLOW_HANDLER.loadContents(_flow.contents);
		}
	}

	if (isset(_RUN_TIMEOUT)) {
		console.log('[Engine] remove app run interval');
		clearInterval(_RUN_TIMEOUT);
		_RUN_TIMEOUT = null;

	}

	runFlow();
}

var _FLOW_TIMEOUT;

function runFlow() {
	if (_FLOW_HANDLER) {
		_FLOW_HANDLER.tick();
		if (!_FLOW_TIMEOUT) {
			console.log('[FLOW] create flow interval ' + FLOW_SLOWNESS);
			_FLOW_TIMEOUT = setInterval(runFlow, FLOW_SLOWNESS * 1000);
		}
	} else {
		if (isset(_FLOW_TIMEOUT)) {
			console.log('[FLOW] remove flow interval');
			clearInterval(_FLOW_TIMEOUT);
			_FLOW_TIMEOUT = null;
		}
	}
}

function setDisplayName(name) {
	setLocal('display_name', name);
	_displayName = name;
	document.getElementById('display_name').innerHTML = name;
}

function setDisplayUser(name) {
	setLocal('display_user', name);
	_displayUser = name;
	document.getElementById('stats_display_user').innerHTML = name ? ' [' + name + '] ' : '';
	if (name) {
		$('#stats_display_user').removeClass('hide');
	} else {
		$('#stats_display_user').addClass('hide');
	}
}

function setClaimDate(date) {
	if (getLocal('claim_date') !== date && date !== null) {
		if (_RESIZE_TIMEOUT) {
			clearTimeout(_RESIZE_TIMEOUT);
			_RESIZE_TIMEOUT = null;
		}
		_RESIZE_TIMEOUT = setTimeout(sendDimensions, 300000);
	}
	_displayDate = date;
	setLocal('claim_date', date);
	document.getElementById('claimed_date').innerHTML = date;
}

function setOwnerLogo(logo) {

	setLocal('owner_logo', logo);
	_ownerLogo = logo;

	if (logo) {
		document.getElementById('owner_logo').style.backgroundImage = "url(" + _ownerLogo + ")";
	} else {
		document.getElementById('owner_logo').style.backgroundImage = null;
	}
}

function loadBg(num) {
	var container = document.getElementById('valota_app_container');
	if (_applicationData[num].palette.palette.backgrounds[_VALOTA_CUR_BG].type === 'image') {
		container.style.backgroundColor = _applicationData[num].palette.palette.backgrounds[_VALOTA_CUR_BG].color;
		container.style.backgroundImage = 'url(' + _applicationData[num].palette.palette.backgrounds[_VALOTA_CUR_BG].url + ')';
		container.style.backgroundSize = 'cover';
	} else {
		// color
		container.style.backgroundColor = _applicationData[num].palette.palette.backgrounds[_VALOTA_CUR_BG].color;
		container.style.backgroundImage = '';
		container.style.backgroundSize = '';
	}
}

function preloadBgs(num) {
	if (isEmpty(_applicationData[num].palette) || isEmpty(_applicationData[num].palette.palette) || isEmpty(_applicationData[num].palette.palette.backgrounds) || (_applicationData[num].isOverlay && videoPlayedSeparately())) {
		return;
	}
	if (!_applicationData[num].hasOwnProperty('bgs')) {
		_applicationData[num].bgs = [];
	}
	var i;
	for (i = 0; i < _applicationData[num].bgs.length; i++) {
		_applicationData[num].bgs[i].inUse = false;
	}
	for (i = 0; i < _applicationData[num].palette.palette.backgrounds.length; i++) {
		if (_applicationData[num].palette.palette.backgrounds[i].type === 'image') {
			var found = false;
			for (var j = 0; j < _applicationData[num].bgs.length; j++) {
				if (_applicationData[num].bgs[j].url === _applicationData[num].palette.palette.backgrounds[i].url) {
					_applicationData[num].bgs[j].inUse = true;
					found = true;
				}
			}
			if (!found) {
				var img = new Image();
				img.src = _applicationData[num].palette.palette.backgrounds[i].url;
				console.log("preloading " + _applicationData[num].palette.palette.backgrounds[i].url);
				_applicationData[num].bgs.push({
					url: _applicationData[num].palette.palette.backgrounds[i].url,
					inUse: true,
					img: img
				});
			}
		}
	}
	for (i = _applicationData[num].bgs.length - 1; i >= 0; i--) {
		if (!_applicationData[num].bgs[i].inUse) {
			_applicationData[num].bgs.splice(i, 1);
		}
	}
}

function generateLocalFlow() {
	_flow = {config: {type: 'static', uuid: 'local'}, contents: []};
	var updateId = "";
	for (var i = 0; i < _applicationData.length; i++) {
		updateId += _applicationData[i].source.changeID;
		if (!_applicationData[i].isOverlay) {
			_flow.contents.push({type: 1, volume: 1, uuid: _applicationData[i].uuid});
		}
	}
	_flow.config.updateId = updateId;
	setLocal('flow', _flow);
}

var _RUN_TIMEOUT;

function runStories() {

	// if we don't have a current story then wait for the first one to be ready
	if (typeof _applicationData !== 'undefined') {
		if (_flow) {
			if (!_FLOW_HANDLER || currentStory === -1) {
				reFlow();
			}

		} else {
			generateLocalFlow();
			reFlow();
		}
	}
}

// check if function exists and then run it
function runFunction(func, funcName, appId, args) {

	try {
		if (typeof func === 'function') {
			if (typeof args !== 'undefined') {
				return func(args);
			}
			return func();
		}
		console.error("[Engine] " + appId + " trying to run non-existent function " + funcName);
	} catch (err) {
		errorOn(appId);
		logError(funcName + ' caused error: ' + err.toString(), appId);
	}

	return false;

}


var _APP_WINDOWS = ["working", "valota_container", "waiting_for_content", "claim_display", "enter_pin", "not_unique"];
var _CURRENT_WINDOW = "working";

function showWindow(id) {
	var new_id;
	if (!continueRunning) {
		new_id = "not_unique";
	} else {
		new_id = id;
	}
	let change = new_id !== _CURRENT_WINDOW;

	if (typeof ValotaAndroid !== 'undefined') {
		if (new_id === 'valota_container') {
			ValotaAndroid.contentRunning();
		} else if (new_id === "enter_pin") {
			ValotaAndroid.contentNotRunning();
		}
	}
	for (var i = 0; i < _APP_WINDOWS.length; ++i) {
		var targ = document.getElementById(_APP_WINDOWS[i]);
		if (_APP_WINDOWS[i] === new_id) {
			targ.style.display = 'block';
			if (new_id === 'enter_pin') {
				targ.style.display = 'grid';
			}
			_CURRENT_WINDOW = new_id;
		} else {

			targ.style.display = 'none';
		}
	}

	if (change) {

		if (_DELAYED_RELOAD) {
			clearTimeout(_DELAYED_RELOAD);
			_DELAYED_RELOAD = null;
		}

		if (['enter_pin', 'claim_display'].indexOf(new_id) !== -1) {
			//start delayed reload
			_DELAYED_RELOAD = window.setTimeout(reloadDisplay, _DELAYED_RELOAD_TIME);

		}
	}


}

var _DELAYED_RELOAD = null;
var _DELAYED_RELOAD_TIME = 10 * 60 * 1000;

function reloadDisplay(bool) {
	location.reload(bool);
}


var _STARTING_PLAY = false;

function playOverlay(num) {
	if (!_applicationData[num].isOverlay || _applicationData[num].uuid !== _overlayApp) {
		console.error('[Engine] playing wrong overlay app', num);
		return;
	}
	if (videoPlayedSeparately()) {
		getDeviceClass().playOverlayApp(_applicationData[num]);
		return;
	}
	// return if no container
	if (!_applicationData[num] || !_applicationData[num].container) {
		return;
	}
	$('#overlay').show();
	runFunction(_applicationData[num].container.contentWindow.ValotaShow, 'ValotaShow', _applicationData[num].uuid);
	_applicationData[num].container.className = 'active';
}

function stopOverlay(num) {
	if (!_applicationData[num].isOverlay || _applicationData[num].uuid !== _overlayApp) {
		console.error('[Engine] stopping wrong overlay app', num);
		return;
	}
	if (videoPlayedSeparately()) {
		getDeviceClass().stopOverlayApp();
		return;
	}
	// return if no container
	if (!_applicationData[num] || !_applicationData[num].container) {
		return;
	}
	runFunction(_applicationData[num].container.contentWindow.ValotaHide, 'ValotaHide', _applicationData[num].uuid);
	_applicationData[num].container.className = 'inactive';
	$('#overlay').hide();

}

function playStory(num) {
	_STARTING_PLAY = true;
	// no point switching to oneself
	if (num === currentStory) {
		//same story
		// hide next content
		hideNextContent();
		// get app's name if it's in different flow
		document.getElementById('now_playing').innerHTML = getCurrentAppName(num);
		// call cycle
		console.log('[ENGINE] Cycle called from playStory() when playing self');
		runFunction(_applicationData[currentStory].container.contentWindow.ValotaCycle, 'ValotaCycle', _applicationData[currentStory].uuid);
		return;
	}

	// let's ignore transition times from flow run times
	FLOW_PAUSED = true;
	// hide old

	$('#valota_app_container').toggleClass('show', false);

	var transferTime = 0;
	//hide header after transition time
	if (currentStory !== -1) {
		// we don't have current story now
		/*if (_SHOW_TITLE) {
		 transferTime = _TRANSITION_TIME;
		 }*/
		setTimeout(function () {

			document.getElementById('now_playing').className = 'scarce';

			// return if no container
			if (!_applicationData[currentStory] || !_applicationData[currentStory].container) {
				return;
			}

			// inactive class and hide callback
			_applicationData[currentStory].container.className = 'inactive';
			var next = runFunction(_applicationData[currentStory].container.contentWindow.ValotaHide, 'ValotaHide', _applicationData[currentStory].uuid);
			ValotaEngine.Video.stopVideosByApp(_applicationData[currentStory].uuid);
			if (isset(next)) {
				_applicationData[currentStory].playNext = next;
			}


		}, transferTime);

	}
	hideNextContent();

	if (_SHOW_TITLE) {
		transferTime += _TRANSITION_TIME;
	}
	setTimeout(function () {
// show header
		document.getElementById('now_playing').className = '';

		document.getElementById('now_playing').innerHTML = getCurrentAppName(num);

		setTimeout(function () {


			// active class and show callback
			for (var i = 0; i < _applicationData.length; ++i) {
				if (!_applicationData[i].container) {
					continue;
				}
				if (i !== num) {
					_applicationData[i].container.className = 'inactive';
				} else {
					_applicationData[i].container.className = 'active';
				}
			}

			// return if no container
			if (!_applicationData[num] || !_applicationData[num].container) {
				return;
			}

			if (typeof ValotaAndroid !== 'undefined') {
				console.log("[Engine Android] heavycontent: " + (typeof _applicationData[num].container.contentWindow.ValotaHeavyContent));
				if (typeof _applicationData[num].container.contentWindow.ValotaHeavyContent === 'function' && _applicationData[num].container.contentWindow.ValotaHeavyContent()) {
					ValotaAndroid.setHWAcceleration(false);
				} else {
					ValotaAndroid.setHWAcceleration(true);
				}
			}
			loadBg(num);
			runFunction(_applicationData[num].container.contentWindow.ValotaShow, 'ValotaShow', _applicationData[num].uuid, _applicationData[num].playNext);
			_applicationData[num].playNext = null;
			$('#valota_app_container').toggleClass('show', true);

			currentStory = num;
			FLOW_PAUSED = false;


		}, _TRANSITION_TIME);


	}, transferTime);

	showWindow('valota_container');
}


function getCurrentAppName(num) {
	if (_FLOW_HANDLER) {
		return _FLOW_HANDLER.getName();
	} else if (isset(_applicationData[num])) {
		return _applicationData[num].name;
	}

	return _('Untitled');

}

/**
 * Checks whether the platforms supports required technologies
 *
 * @returns {Boolean}
 */
function checkCompatibility() {

	if (localStorage === null || typeof localStorage === 'undefined') {
		return false;
	}

	return true;
}

function getStoryLoc(uuid) {

	if (typeof _applicationData === "undefined") {
		console.error("[Engine] _applicationData was undefined and couldn't find " + uuid + " in getStoryLoc()");
		return -1;
	}
	for (var i = 0; i < _applicationData.length; ++i) {
		if (_applicationData[i].uuid === uuid) {
			return i;
		}
	}
	//console.error("[Engine] Couldn't find " + uuid + " in getStoryLoc()");
	return -1;

}

function getLoadingDiv(cls) {

	var div = document.createElement('div');
	div.className = 'inline_loader_logo active' + (typeof cls === 'string' ? ' ' + cls : '');
	return div;
}

function setClocks() {
	var dateNow, element, newTime, offset;
	var curTime = Date.now() + _TIME_CHECK;


	var waiting_clock_set = false;
	if (_SHOW_TITLE_CLOCK) {
		offset = typeof _SHOW_TITLE_CLOCK.offset !== 'undefined' ? _SHOW_TITLE_CLOCK.offset : 0;
		dateNow = new Date(curTime + (offset * 1000));
		element = document.getElementById('title_clock');
		newTime = formatTime(dateNow, _SHOW_TITLE_CLOCK.am);
		if (element.innerHTML !== newTime) {
			element.innerHTML = newTime;
		}

		element = document.getElementById('waiting_clock');
		if (element.innerHTML !== newTime) {
			waiting_clock_set = true;
			element.innerHTML = newTime;
		}
	}

	if (_SHOW_STATS_CLOCK) {
		offset = typeof _SHOW_STATS_CLOCK.offset !== 'undefined' ? _SHOW_STATS_CLOCK.offset : 0;
		dateNow = new Date(curTime + (offset * 1000));
		element = document.getElementById('stats_clock');
		newTime = formatTime(dateNow, _SHOW_STATS_CLOCK.am);
		if (element.innerHTML !== newTime) {
			element.innerHTML = newTime;
		}

		if (!waiting_clock_set) {
			element = document.getElementById('waiting_clock');
			if (element.innerHTML !== newTime) {
				element.innerHTML = newTime;
			}
		}
	}

}

function addNull(val) {
	return val < 10 ? "0" + val : val;
}

function formatTime(dateObj, am) {
	if (!am) {
		return addNull(dateObj.getUTCHours()) + ':' + addNull(dateObj.getUTCMinutes());
	}

	var hours = dateObj.getUTCHours();
	var unit = 'a.m.';
	if (hours === 0) {
		hours = 12;
	} else if (hours > 12) {
		hours -= 12;
		unit = 'p.m.';
	}
	return hours + ':' + addNull(dateObj.getUTCMinutes()) + ' ' + unit;

}

var _CLOCK_TIMEOUT = false;

function engineLayout() {
	var header = document.getElementById('header_container');
	if (_SHOW_TITLE) {
		header.style.display = 'block';
		$('#valota_app_container').toggleClass('noTitle', false);


	} else {
		header.style.display = 'none';
		$('#valota_app_container').toggleClass('noTitle', true);
	}

	if (_SHOW_LOGO) {
		$('#valota_app_container').removeClass('noLogo');
	} else {
		$('#valota_app_container').addClass('noLogo');
	}

	if (_SHOW_STATS) {
		document.getElementById('display_stats').style.display = 'block';
		$('#valota_app_container').toggleClass('stats', true);
	} else {
		document.getElementById('display_stats').style.display = 'none';
		$('#valota_app_container').toggleClass('stats', false);
	}

	if (_SHOW_NEXT) {
		$('#up_next').toggleClass('hidden', false);
	} else {
		$('#up_next').toggleClass('hidden', true);
	}

	var _CLOCK_IS_ON = false;
	if (_SHOW_TITLE && _SHOW_TITLE_CLOCK) {
		$('#header_container').toggleClass('clock', true);
		_CLOCK_IS_ON = true;
	} else {
		$('#header_container').toggleClass('clock', false);
	}

	if (_SHOW_STATS && _SHOW_STATS_CLOCK) {
		$('#display_stats').toggleClass('clock', true);
		_CLOCK_IS_ON = true;
	} else {
		$('#display_stats').toggleClass('clock', false);
	}

	if (_SHOW_TITLE_CLOCK || _SHOW_STATS_CLOCK) {
		$('#waiting_clock').toggleClass('hidden', false);
	} else {
		$('#waiting_clock').toggleClass('hidden', true);
	}

	if (_CLOCK_IS_ON) {

		if (!_CLOCK_TIMEOUT) {
			_CLOCK_TIMEOUT = setInterval(setClocks, 10000);
		}
		setClocks();

	} else if (_CLOCK_TIMEOUT) {
		clearInterval(_CLOCK_TIMEOUT);
		_CLOCK_TIMEOUT = false;

	}


	document.getElementById('stats_display_name').innerHTML = _displayName;
	document.getElementById('stats_display_user').innerHTML = _displayUser ? ' [' + _displayUser + '] ' : '';
	if (_displayUser) {
		$('#stats_display_user').removeClass('hide');
	} else {
		$('#stats_display_user').addClass('hide');
	}
}

function formatSmallStats() {
	let stats;
	var fails = document.getElementById('last_fetches_failed');
	if(_WEBSOCKET) {
		stats = "Using WebSocket";
		if(ValotaEngine.WebSocket.disconnectedConnected_ms) {
			stats += " disconnected  at " + (new Date(ValotaEngine.WebSocket.disconnectedConnected_ms)).toISOString();
		}
		fails.className = 'hide';
	} else {
		let total = _FETCH_SUCCESSFUL + _FETCH_FAILS;
		stats = '<span class="bold">' + total + '</span>';
		stats += ' fetches';
		if(total > 0) {
			stats += ' with a success <span class="bold">' + Math.round((_FETCH_SUCCESSFUL / total) * 10000) / 100 + '%</span>';
		}



		if (_FETCH_FAILS_IN_ROW) {
			var text = '<span class="bold">' + _FETCH_FAILS_IN_ROW + '</span> failed in a row';
			fails.innerHTML = text;
			fails.className = '';
		} else {
			fails.className = 'hide';
		}
	}



	document.getElementById('stats_little_stats').innerHTML = stats;


}

function setRunTime(num, s) {
	var tested = 10;
	if (parseInt(s)) {
		tested = parseInt(s);
		if (tested > _MAX_RUN_TIME) {
			tested = _MAX_RUN_TIME;
		}
	} else {
		logError("garbage run time " + s, _applicationData[num].uuid, "error");
	}
	_applicationData[num].runTime = tested;
}

function setCycleTime(num, s) {
	var tested = 10;
	if (parseInt(s)) {
		tested = parseInt(s);
		tested = Math.max(Math.min(tested, _MAX_CYCLE_TIME), 1);
	} else {
		logError("garbage cycle time " + s, _applicationData[num].uuid, "error");
	}
	if (tested !== s) {
		console.warn('[Engine] Cycle time set to ' + tested + ' instead of ' + s);
	}

	_applicationData[num].cycleTime = tested;

	/*if(_FLOW_HANDLER) {
	 var myFlowContent = _FLOW_HANDLER.getContent(_applicationData[num].uuid);
	 console.log("[Engine] cycletime", s, _applicationData[num].runTime, myFlowContent.hasRun);
	 if (_applicationData[num].runTime < myFlowContent.hasRun + s) {
	 _applicationData[num].runTime = myFlowContent.hasRun + s;
	 console.log("[Engine] runtime increase to", _applicationData[num].runTime);
	 }
	 } else {
	 if (_applicationData[num].runTime <  s) {
	 _applicationData[num].runTime = s;
	 console.log("[Engine] runtime decrease to", _applicationData[num].runTime);
	 }
	 }*/


}

function setPreferredCycles(num, s) {
	var tested = 1;
	if (parseInt(s)) {
		tested = Math.max(Math.min(parseInt(s), _MAX_CYCLES), 1);
		if (tested !== s) {
			console.warn('[Engine] Preferred cycles set to ' + tested + ' instead of ' + s);
		}
	} else {
		logError("garbage preferred cycles " + s, _applicationData[num].uuid, "error");
	}
	_applicationData[num].preferredCycles = tested;

}

var _CHANGE_LEVEL = Infinity;

function showNextContent(name, level) {
	console.log(name, level);
	if (level > _CHANGE_LEVEL) {
		return;
	}
	_CHANGE_LEVEL = level;

	var next = $('#up_next');
	next.toggleClass('visible', true);
	next.html(name);
}

function hideNextContent() {
	_CHANGE_LEVEL = Infinity;
	var next = $('#up_next');
	next.toggleClass('visible', false);
}


function gat(agoSeconds, format) {
	if (isNaN(agoSeconds)) {
		return '';
	}
	if (typeof format === 'undefined')
		format = 'original';

	if (format === 'original') {
		if (agoSeconds < 60) {
			return _("under a minute");
		} else if (agoSeconds < 3600) {
			agoSeconds = Math.round(agoSeconds / 60);
			if (agoSeconds === 1)
				return _("a minute");
			else
				return _("X minutes".replace("X", agoSeconds));
		} else if (agoSeconds < 86400) {
			agoSeconds = Math.round(agoSeconds / 3600);
			if (agoSeconds === 1)
				return _("an hour");
			else
				return _("X hours".replace("X", agoSeconds));
		} else if (agoSeconds < 604800) {
			agoSeconds = Math.round(agoSeconds / 86400);
			if (agoSeconds === 1)
				return _("a day");
			else
				return _("X days".replace("X", agoSeconds));
		} else if (agoSeconds < 2419200) {
			agoSeconds = Math.round(agoSeconds / 604800);
			if (agoSeconds === 1)
				return _("a week");
			else
				return _("X weeks".replace("X", agoSeconds));
		} else if (agoSeconds < 29030400) {
			agoSeconds = Math.round(agoSeconds / 2419200);
			if (agoSeconds === 1)
				return _("a month");
			else
				return _("X months".replace("X", agoSeconds));
		} else {
			agoSeconds = Math.round(agoSeconds / 29030400);
			if (agoSeconds === 1)
				return _("a year");
			else
				return _("X years".replace("X", agoSeconds));
		}
	} else if (format === 'short') {
		if (agoSeconds < 60) {
			return _("now");
		} else if (agoSeconds < 3600) {
			agoSeconds = Math.round(agoSeconds / 60);
			return _("Xm".replace("X", agoSeconds));
		} else if (agoSeconds < 86400) {
			agoSeconds = Math.round(agoSeconds / 3600);
			return _("Xh".replace("X", agoSeconds));
		} else if (agoSeconds < 604800) {
			agoSeconds = Math.round(agoSeconds / 86400);
			return _("Xd".replace("X", agoSeconds));
		} else if (agoSeconds < 2419200) {
			agoSeconds = Math.round(agoSeconds / 604800);
			return _("Xw".replace("X", agoSeconds));
		} else if (agoSeconds < 29030400) {
			agoSeconds = Math.round(agoSeconds / 2419200);
			return _("XM".replace("X", agoSeconds));
		} else {
			agoSeconds = Math.round(agoSeconds / 29030400);
			return _("XY".replace("X", agoSeconds));
		}
	}
}

function newContentOnApp(uuid, id) {
	var num = getStoryLoc(uuid);
	if (num === -1) {
		return;
	}

	_applicationData[num].latestContent = Date.now();

	if (isset(id)) {
		_applicationData[num].playNext = id;
	}

	if (_FLOW_HANDLER) {
		_FLOW_HANDLER.newContentOnContent(uuid, _applicationData[num].latestContent);
	}
}

function registerStore(readyCallback) {
	_READY_CALLBACK = readyCallback;
}

var LAST_RED_BUTTON_EPOCH_MS = 0;

function redButton() {
	if (Date.now() - LAST_RED_BUTTON_EPOCH_MS < 10000) {
		//max one panic button every ten seconds
		return;
	}
	document.getElementById('success_sound').play();
	document.getElementById('notification').innerHTML = 'Thank you. Error has been logged.';
	document.getElementById('notification').className = 'active';
	setTimeout(function () {
		document.getElementById('notification').className += ' checkout';
	}, 100);

	setTimeout(function () {
		document.getElementById('notification').className = '';
	}, 6000);

	var app_uuid = false;
	if (currentStory !== -1) {
		app_uuid = _applicationData[currentStory].uuid;
	}

	var window_width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	var window_height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;


	var error_uuid = makeId(18);
	var request = {
		action: "valota_api/displays/red_button",
		display_uuid: _displayID,
		running_app_uuid: app_uuid,
		errors: JSON.stringify(_PREVIOUS_100_ERRORS),
		application_data: JSON.stringify(_applicationData),
		flow: JSON.stringify(_flow),
		error_uuid: error_uuid,
		user_agent: navigator.userAgent,
		window_width: window_width,
		window_height: window_height
	};

	LAST_RED_BUTTON_EPOCH_MS = Date.now();

	sendScreenshot(app_uuid, error_uuid);

	$.ajax({
		type: "POST",
		url: _VALOTA_REST,
		data: request,
		success: function () {

		},
		error: function (xhr, textStatus, errorThrown) {
			//TODO: save for later if fails
		},
		dataType: "json"
	});
}

function takeScreenshot() {

	var app_uuid = false;
	if (currentStory !== -1) {
		app_uuid = _applicationData[currentStory].uuid;
	}

	sendScreenshot(app_uuid, 0);

}

function sendScreenshot(app_uuid, error_uuid) {

	if (typeof getDeviceClass().getScreenshot === 'function') {
		getDeviceClass().getScreenshot(getScreenshot);
	}

	function getScreenshot(base64) {

		if (!base64) {
			return;
		}
		var boundingBox = JSON.stringify(document.getElementById('valota_app_container').getBoundingClientRect());
		var request = {
			action: "valota_api/displays/screenshot",
			display_uuid: _displayID,
			app_uuid: app_uuid,
			error_uuid: error_uuid,
			app_area: boundingBox,
			imageBase64: base64
		};

		$.ajax({
			type: "POST",
			url: _VALOTA_REST,
			data: request,
			success: function () {

			},
			error: function (xhr, textStatus, errorThrown) {
				//TODO: save for later if fails
			},
			dataType: "json"
		});


	}

}


function handleKey(event) {

	switch (event.code) {
		case 'Digit1':
			if (event.shiftKey) {
				redButton();
			}
			break;

		case 'Digit5':
			if (event.shiftKey) {
				takeScreenshot();
			}
			break;
	}

}

window.onkeydown = function (event) {
	handleKey(event);
};

function shouldSendScreenshotData() {
	// determine if engine should send screenshots
	return false;

}

function reloadApp() {
	if (typeof getDeviceClass().reloadApp === 'function') {
		getDeviceClass().reloadApp();
	}
}


function getRequestData() {
	var request = {};
	request.displayUUID = _displayID;
	request.displayUpdated = _DISPLAY_UPDATED;


	if (typeof _SERVED_APP !== "undefined" && _SERVED_APP) {
		request.servedApp = _SERVED_APP;
	}

	if (staticServe()) {
		request.staticServe = 1;
	}


	if (hasApps()) {
		request.apps = [];

		for (var i = 0; i < _applicationData.length; ++i) {
			var ad = _applicationData[i];
			var add_on = {};
			add_on.uuid = ad.uuid;

			// source
			if (ad.source) {
				add_on.source = {
					id: ad.source.id
				};

				if (typeof ad.source.changeID !== 'undefined') {
					add_on.source.changeID = ad.source.changeID;
				}

				if (typeof ad.source.updateTime !== 'undefined') {
					add_on.source.updateTime = ad.source.updateTime;
				}

			}

			if (ad.palette) {
				add_on.paletteId = ad.palette.id;
			}

			if (ad.customs) {
				add_on.customsUpdateTime = ad.customs.updateTime;
			}

			request.apps.push(add_on);


		}
	}

	if (hasFlow() && _flow.config.uuid !== 'local') {
		request.flow = {
			'uuid': _flow.config.uuid,
			'updateId': _flow.config.updateId
		};
	}
	return request;
}

let _IS_OFFLINE = !window.navigator.onLine;
function setOffline() {

	_IS_OFFLINE = true;
	_FETCH_FAILS_REASON = "Offline";
	if(ValotaEngine.WebSocket.connected()) {
		ValotaEngine.WebSocket.timesync();
	}
	setStatus();

}

function setOnline() {
	_IS_OFFLINE = false;
	_FETCH_FAILS_REASON = null;
	if(doFetch() && ValotaEngine.WebSocket.supported() && _WEBSOCKET && !ValotaEngine.WebSocket.connected()) {
		ValotaEngine.WebSocket.reconnect();
	}
	setStatus();
}

window.addEventListener('offline', function(e) {
	setOffline();
});

window.addEventListener('online', function(e) {
	setOnline();
});

function setDisplayStarted() {
	const el =document.getElementById('stats_display_started');
	if(el) {
		el.innerHTML = gat(Math.round((Date.now() - _DISPLAY_ONLINE.getTime()) / 1000));
	}

}
setInterval(setDisplayStarted, 60000);
