/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */

// TODO enable parallelism
// TODO memory checks

if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}

ValotaEngine.Android = {
	inited: false,
	PIN: null,
	callback: null,
	cacheQueue: [],
	files: {},
	ready: false,
	videoOwners: [],
	screenshotCallback: null,
	start: function () {
		if (typeof window.ValotaAndroid === "undefined" || typeof window.ValotaAndroid.loadUUID !== "function") {
			console.error("[Engine Android] error");
			return false;
		}
		if (window.ValotaAndroid.loadUUID() !== null) {
			console.log("[Engine Android] got UUID " + window.ValotaAndroid.loadUUID());
		}
		return true;
	},
	clearUUID: function () {
		window.ValotaAndroid.clearUUID();
	},
	saveUUID: function (uuid) {
		if (!window.ValotaAndroid.saveUUID(uuid)) {
			console.error("[Engine Android] Unable to save UUID to device");
			logError("[Android] Unable to save UUID to device");
		}
	},
	getScreenshot: function (callback) {
		window.ValotaAndroid.takeScreenshot();
		ValotaEngine.Android.screenshotCallback = callback;
	},
	reloadApp: function () {
		window.ValotaAndroid.reloadApp();
	},
	screenshotTaken(base64) {
		if (typeof ValotaEngine.Android.screenshotCallback === 'function') {
			/*var binary_string = window.atob(base64);
			 var len = binary_string.length;
			 var bytes = new Uint8Array(len);
			 for (var i = 0; i < len; i++) {
			 bytes[i] = binary_string.charCodeAt(i);
			 }*/
			ValotaEngine.Android.screenshotCallback("data:image/jpeg;base64," + base64);
		}
	},
	log(message) {
		console.log("[Engine Android] Log from the Android app:", message);
		if (typeof _VALOTA_LOG_ANDROID !== 'undefined' && _VALOTA_LOG_ANDROID === true) {
			if (message.length > 0) {
				var request = {action: "valota_api/log/android_log", appUUID: "ValotaPlayer", displayUUID: _displayID, message: message};
				$.ajax({
					type: "POST",
					url: _VALOTA_REST,
					data: request,
					dataType: "json"
				}).done(function () {})
					.fail(function (xhr, st, err) {
						var mes = 'Failed to send log: ' + err + ' (' + st + ')';
						console.error("[Engine Android] " + mes);
					});
			}
		}
	},
	cached: function (url) {
		if (ValotaEngine.Android.url !== url) {
			console.error("[Engine Android] bad coding");
			if (ValotaEngine.Android.callback !== null) {
				ValotaEngine.Android.callback(ValotaEngine.Android.name, url, '', {status: "error", message: _('Bad coding')});
			}
			ValotaEngine.Android.callback = null;
			ValotaEngine.Android.name = null;
			ValotaEngine.Android.url = null;
			return;
		}
		if (ValotaEngine.Android.callback !== null) {
			ValotaEngine.Android.callback(ValotaEngine.Android.name, ValotaEngine.Android.url, '', {status: 'ok'});
		}
		ValotaEngine.Android.files[ValotaEngine.Android.name].url = url;
		ValotaEngine.Android.files[ValotaEngine.Android.name].blobUrl = window.ValotaAndroid.getVideo(url);
		ValotaEngine.Android.files[ValotaEngine.Android.name].inMemory = true;

		ValotaEngine.Android.callback = null;
		ValotaEngine.Android.name = null;
		ValotaEngine.Android.url = null;
		var cacheRetrievalObj = ValotaEngine.Android.cacheQueue.shift();
		if (typeof cacheRetrievalObj !== "undefined") {
			ValotaEngine.Android.cacheFile(cacheRetrievalObj.name, cacheRetrievalObj.url, cacheRetrievalObj.callback);
		}
	},
	cacheFile: function (name, url, callback) {
		console.log("[Engine Android] Cache " + url);
		if (typeof ValotaEngine.Android.files[name] !== 'undefined') {
			if (ValotaEngine.Android.files[name].inMemory === true) {
				window.setTimeout(function () {
					callback(name, url, '', {status: 'ok'});
				}, 10);
				return;
			} else if (ValotaEngine.Android.files[name].loading === true) {
				// already loading this to memory
				throw "bad coding, already loading";
			}
			throw "bad coding, corrupted cache?";
		}

		if (ValotaEngine.Android.callback !== null) {
			var tempObj = {};
			tempObj.name = name;
			tempObj.url = url;
			tempObj.callback = callback;
			ValotaEngine.Android.cacheQueue.push(tempObj);
			return;
		}
		ValotaEngine.Android.callback = callback;
		ValotaEngine.Android.name = name;
		ValotaEngine.Android.url = url;
		ValotaEngine.Android.files[name] = {};
		ValotaEngine.Android.files[name].inMemory = false;
		ValotaEngine.Android.files[name].url = null;
		ValotaEngine.Android.files[name].blobUrl = null;
		window.ValotaAndroid.fetchVideo(url);
		return true;
	},
	fileCachedCallback: function (name, url, unused, status) {
		console.log("[Engine Android] file cached", url, status.status);
		for (var i = 0; i < ValotaEngine.Video.cacheList[name].cb.length; i++) {
			ValotaEngine.Video.cacheList[name].cb[i](url, status);
		}
		delete ValotaEngine.Video.cacheList[name];
	},
	playVideo: function (uuid, url, elem, playStartedCB, endedCB, errorCB, iframeRect) {
		var name = "v_" + JSON.stringify(url).replace(/[^0-9a-z]/gi, '');
		if ((!(name in ValotaEngine.Android.files)) || ValotaEngine.Android.files[name].loading) {
			throw "trying to play an uncached video";
		}
		if (!ValotaEngine.Android.files[name].inMemory) {
			throw "trying to play an uncached video, not in memory";
		}
		if (!(uuid in ValotaEngine.Android.videoOwners)) {
			ValotaEngine.Android.videoOwners[uuid] = {elem: null, videoStartedPlayingCB: null, videoEndedCB: null, videoErrorCB: null, videoConf: {mute: true}};
		}
		if (ValotaEngine.Android.videoOwners[uuid].elem !== null) {
			if (ValotaEngine.Android.videoOwners[uuid].elem === elem) {
				throw "bad coding, use replayVideo to replay a video :O";
			}
			throw "bad coding, a video set when trying to play video. stop first";
		}

//		console.log(elem.getBoundingClientRect());
//		var rect = elem.getBoundingClientRect();

//		var coordObj = {width: rect.width, height: rect.height, left: (rect.left + iframeRect.left), top: (rect.top + iframeRect.top)};
		ValotaEngine.Android.videoOwners[uuid].elem = elem;
		ValotaEngine.Android.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.Android.videoOwners[uuid].videoEndedCB = endedCB;
		ValotaEngine.Android.videoOwners[uuid].videoErrorCB = function (e) {
			ValotaEngine.Android.stopVideo(uuid,elem);
			// window.ValotaAndroid.deleteFromCache(name); // not implemented
			e.cmd = 'videoErrored';
			e.url = url;
			errorCB(e);
		};
		ValotaEngine.Android.videoOwners[uuid].url = url;

//		ValotaEngine.Android.videoOwners[uuid].engineElem = document.createElement('video');
//		ValotaEngine.Android.videoOwners[uuid].engineElem.style.position = "absolute";
//		ValotaEngine.Android.videoOwners[uuid].engineElem.style.top = coordObj.top + "px";
//		ValotaEngine.Android.videoOwners[uuid].engineElem.style.left = coordObj.left + "px";
//		ValotaEngine.Android.videoOwners[uuid].engineElem.style.width = coordObj.width + "px";
//		ValotaEngine.Android.videoOwners[uuid].engineElem.style.height = coordObj.height + "px";
		ValotaEngine.Android.videoOwners[uuid].elem.addEventListener("ended", ValotaEngine.Android.videoOwners[uuid].videoEndedCB);
		ValotaEngine.Android.videoOwners[uuid].elem.addEventListener("error", ValotaEngine.Android.videoOwners[uuid].videoErrorCB);
		ValotaEngine.Android.videoOwners[uuid].elem.dataset.ownerUuid = uuid;
		//document.body.append(ValotaEngine.Android.videoOwners[uuid].eengineElem);
		ValotaEngine.Android.videoOwners[uuid].elem.src = ValotaEngine.Android.files[name].blobUrl;
		ValotaEngine.Android.videoOwners[uuid].elem.muted = ValotaEngine.Video.getMuteStatus(uuid);
		ValotaEngine.Android.videoOwners[uuid].elem.play().then(function () {
			playStartedCB(ValotaEngine.Android.videoOwners[uuid].elem.duration, uuid);
		}).catch(function (ev) {
			console.error("failed playing video",ev);
			errorCB(ev, uuid);
		});
	},
	resizeVideo: function (uuid, elem, iframeRect) {
		// do nothing
	},
	stopVideo: function (uuid, elem) {
		if (typeof ValotaEngine.Android.videoOwners[uuid] === 'undefined') {
			throw "unknown uuid"; // never played anything
		}
		if (ValotaEngine.Android.videoOwners[uuid].elem === null) {
			throw "not running"; // played but not running
		}

		if (ValotaEngine.Android.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to stop video when something else is playing";
		}
		ValotaEngine.Android.videoOwners[uuid].elem.pause();
		ValotaEngine.Android.videoOwners[uuid].elem.removeEventListener("ended", ValotaEngine.Android.videoOwners[uuid].videoEndedCB);
		ValotaEngine.Android.videoOwners[uuid].elem.removeEventListener("error", ValotaEngine.Android.videoOwners[uuid].videoErrorCB);
		ValotaEngine.Android.videoOwners[uuid].elem.src = "";
		ValotaEngine.Android.videoOwners[uuid].elem.load();
//		document.body.removeChild(ValotaEngine.Android.videoOwners[uuid].engineElem);
		ValotaEngine.Android.videoOwners[uuid].elem = null;
		ValotaEngine.Android.videoOwners[uuid].videoEndedCB = null;
		ValotaEngine.Android.videoOwners[uuid].videoErrorCB = null;
		ValotaEngine.Android.videoOwners[uuid].videoStartedPlayingCB = null;
		ValotaEngine.Android.videoOwners[uuid].url = null;
	},
	replayVideo: function (uuid, elem, playStartedCB, errorCB) {
		console.warn("replaying video");

		if (ValotaEngine.Android.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to replay video that is not playing";
		}
		ValotaEngine.Android.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.Android.videoOwners[uuid].videoErrorCB = errorCB;
		ValotaEngine.Android.videoOwners[uuid].elem.play().then(function () {
			playStartedCB(ValotaEngine.Android.videoOwners[uuid].elem.duration, uuid);
		}).catch(function (ev) {
			errorCB(ev, uuid);
		});
	},
	stopVideos: function () {
		for (var uuid in ValotaEngine.Android.videoOwners) {
			if (ValotaEngine.Android.videoOwners[uuid].elem !== null) {
				try {
					ValotaEngine.Android.stopVideo(uuid, ValotaEngine.Android.videoOwners[uuid].elem);
				} catch (e) {
					// do nothing here
				}
			}
		}
	}
};