/*
 * VALOTA CONFIDENTIAL
 * __________________
 *
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */

/* global chrome, URL, _VALOTA_LOG_CHROME_OS, _VALOTA_REST, _displayID, _CHROME_EXTENSION_ID */

// TODO enable parallelism
// TODO memory checks
// https://developer.chrome.com/extensions/messaging#external-webpage

if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}
valotaExtensionFS = null;
ValotaEngine.ChromeExtension = {
	inited: false,
	PIN: null,
	callback: null,
	files: {},
	latitude: 0.0,
	longitude: 0.0,
	videoOwners: [],
	role: null,
	start: function () {
		if (ValotaEngine.ChromeExtension.inited) {
			return;
		}
		window.valotaExtensionFS = new ValotaExtensionFS();
		ValotaEngine.ChromeExtension.inited = true;

		window.addEventListener('message', ValotaEngine.ChromeExtension.onMessage);

		console.log("[Engine ChromeExtension] started");
		chrome.runtime.sendMessage(_CHROME_EXTENSION_ID, {action: "start"},
			function (response) {
				console.log("[Engine ChromeExtension] response start", response);
				if (typeof response === 'undefined' || response.status !== "ok") {
					console.error("[Engine ChromeExtension] couldn't contact the extension " + (typeof response !== 'undefined' ? response.status + " " + response.message : ''));
					document.getElementById('working_text').innerHTML = _('Cannot contact the Extension');
					showWindow('working');
					chrome.runtime.sendMessage(_CHROME_EXTENSION_ID, {action: "exit"},
						function (response) {
							if (typeof response === 'undefined' || response.status !== "ok") {
								console.error("[Engine ChromeExtension] couldn't contact the extension");
							}
						});

				} else {
					if (typeof response.displayID !== 'undefined' && typeof response.role === 'undefined') {
						_displayID = response.displayID;
						loadSavedData();
						chooseStage(true);
					} else if (response.role === 'player') {
						if(typeof response.deviceID !== 'undefined'){
							console.log("[Engine ChromeExtension] device id", response.deviceID);
							sendMyDeviceID(response.deviceID);
						}
						ValotaEngine.ChromeExtension.role = 'player';
						if (document.getElementById('valota_extension_mod_css') === null) {
							var style = document.createElement('style');
							style.type = 'text/css';
							style.setAttribute('id', 'valota_extension_mod_css');
							var css = "div#claim_pin:after{content:' click to enter PIN if you already have one';}";
							if (style.styleSheet) {
								style.styleSheet.cssText = css;
							} else {
								style.innerHTML = css;
							}
							document.getElementsByTagName('head')[0].appendChild(style);
							document.getElementById('enter_pin').addEventListener('click', function () {
								showPIN('really');
							});
						}
						if (typeof response.displayID !== 'undefined' && response.displayID !== null) {
							if (response.displayID !== _displayID) {
								_displayID = response.displayID;
								setLocal('display', _displayID);
							}
						} else {
							if (_displayID === null) {
								_displayID = makeId(32);
							}
							ValotaEngine.ChromeExtension.saveUUID(_displayID);
							setLocal('display', _displayID);
						}
						identifyDisplay();
						loadSavedData();
						chooseStage(true);
					} else {
						document.getElementById('working_text').innerHTML = _('Cannot contact the Extension');
						showWindow('working');
						chrome.runtime.sendMessage(_CHROME_EXTENSION_ID, {action: "exit"},
							function (response) {
								if (typeof response === 'undefined' || response.status !== "ok") {
									console.error("[Engine ChromeExtension] couldn't contact the extension");
								}
							});
					}
				}
			});
		window.addEventListener("resize", function (s) {
			console.log("[Engine ChromeExtension] resize: ", s);
			chrome.runtime.sendMessage(_CHROME_EXTENSION_ID, {action: "resize"},
				function (response) {
					if (typeof response === 'undefined' || response.status !== "ok") {
						console.error("[Engine ChromeExtension] couldn't contact the extension");
					}
				});
		}, false);
		window.addEventListener("keyup", function (e) {
			console.log("[Engine ChromeExtension] response keyup", e);
			if (e.code === "Escape") {
				chrome.runtime.sendMessage(_CHROME_EXTENSION_ID, {action: "exit"},
					function (response) {
						if (typeof response === 'undefined' || response.status !== "ok") {
							console.error("[Engine ChromeExtension] couldn't contact the extension");
						}
					});
			}
		}, true);

	},
	clearUUID: function () {
		if (ValotaEngine.ChromeExtension.role === 'player') {
			chrome.runtime.sendMessage(_CHROME_EXTENSION_ID, {action: "clearUUID"}, function () {
			});
		}
	},
	saveUUID: function (uuid) {
		if (ValotaEngine.ChromeExtension.role === 'player') {
			chrome.runtime.sendMessage(_CHROME_EXTENSION_ID, {action: "saveUUID", displayUUID: uuid}, function () {
			});
		}
	},
	getScreenshot: function (callback) {
		return;
		chrome.runtime.sendMessage(_CHROME_EXTENSION_ID, {action: "get_screenshot"}, function (data) {
			callback(data);
		});
	},
	cacheFile: function (name, url, callback) {
		console.log("[Engine ChromeExtension] loading", url);
		if (typeof ValotaEngine.ChromeExtension.files[name] !== 'undefined') {
			if (ValotaEngine.ChromeExtension.files[name].loading === true) {
				// already loading this to memory, enter the queue
				valotaExtensionFS.loadURL(name, url).then((data) => {
					callback(name, url, '', {status: 'ok'});
				}).catch((e) => {
					callback(name, url, '', {status: 'error', message: "couldn't load: " + e.message});
				});
				return true;
			}
			if (ValotaEngine.ChromeExtension.files[name].blobUrl !== null) {
				window.setTimeout(function () {
					callback(name, url, '', {status: 'ok'});
				}, 10);
				return true;
			}
		}

		ValotaEngine.ChromeExtension.files[name] = {};
		ValotaEngine.ChromeExtension.files[name].file = [];
		ValotaEngine.ChromeExtension.files[name].loading = true;
		ValotaEngine.ChromeExtension.files[name].url = null;
		ValotaEngine.ChromeExtension.files[name].blobUrl = null;
		valotaExtensionFS.loadURL(name, url).then((data) => {
			ValotaEngine.ChromeExtension.files[name].blobUrl = data.blobUrl;
			ValotaEngine.ChromeExtension.files[name].loading = false;
			callback(name, url, '', {status: 'ok'});
		}).catch((e) => {
			ValotaEngine.ChromeExtension.files[name].loading = false;
			callback(name, url, '', {status: 'error', message: "couldn't load: " + e.message});
		});
		return true;
	},
	fileCachedCallback: function (name, url, unused, status) {
		console.log("[Engine ChromeExtension] file cached with " + ValotaEngine.Video.cacheList[name].cb.length + " callbacks", url, status);
		for (var i = 0; i < ValotaEngine.Video.cacheList[name].cb.length; i++) {
			ValotaEngine.Video.cacheList[name].cb[i](url, status);
		}
		delete ValotaEngine.Video.cacheList[name];
	},
	playVideo: function (uuid, url, elem, playStartedCB, endedCB, errorCB, iframeRect) {
		var name = "v_" + JSON.stringify(url).replace(/[^0-9a-z]/gi, '');
		if ((!(name in ValotaEngine.ChromeExtension.files)) || ValotaEngine.ChromeExtension.files[name].loading) {
			console.error("[Engine ChromeExtension]", ValotaEngine.ChromeExtension.files, name);
			throw "trying to play an uncached video";
		}
		if (!(uuid in ValotaEngine.ChromeExtension.videoOwners)) {
			ValotaEngine.ChromeExtension.videoOwners[uuid] = {
				elem: null,
				videoStartedPlayingCB: null,
				videoEndedCB: null,
				videoErrorCB: null,
				videoConf: {mute: true}
			};
		}
		if (ValotaEngine.ChromeExtension.videoOwners[uuid].elem !== null) {
			if (ValotaEngine.ChromeExtension.videoOwners[uuid].elem === elem) {
				throw "bad coding, use replayVideo to replay a video :O";
			}
			throw "bad coding, a video set when trying to play video. stop first";
		}

		ValotaEngine.ChromeExtension.videoOwners[uuid].elem = elem;
		ValotaEngine.ChromeExtension.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.ChromeExtension.videoOwners[uuid].videoEndedCB = endedCB;
		ValotaEngine.ChromeExtension.videoOwners[uuid].videoErrorCB = function (e) {
			ValotaEngine.ChromeExtension.stopVideo(uuid,elem);
			ValotaExtensionFS.deleteFromCache(name);
			e.cmd = 'videoErrored';
			e.url = url;
			errorCB(e);
		};
		ValotaEngine.ChromeExtension.videoOwners[uuid].name = name;
		ValotaEngine.ChromeExtension.videoOwners[uuid].url = url;

//		ValotaEngine.ChromeExtension.videoOwners[uuid].engineElem = document.createElement('video');
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem.addEventListener("ended", ValotaEngine.ChromeExtension.videoOwners[uuid].videoEndedCB);
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem.addEventListener("error", ValotaEngine.ChromeExtension.videoOwners[uuid].videoErrorCB);
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem.dataset.ownerUuid = uuid;
		//document.body.append(ValotaEngine.ChromeExtension.videoOwners[uuid].engineElem);
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem.src = ValotaEngine.ChromeExtension.files[name].blobUrl;
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem.muted = ValotaEngine.Video.getMuteStatus(uuid);
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem.play().then(function () {
			playStartedCB(ValotaEngine.ChromeExtension.videoOwners[uuid].elem.duration, uuid);
			valotaExtensionFS.used(name);
		}).catch(function (ev) {
			ValotaEngine.ChromeExtension.stopVideo(uuid,elem);
			ValotaExtensionFS.deleteFromCache(name);
			ev.cmd = 'videoErrored';
			ev.url = url;
			errorCB(ev);
		});
	},
	resizeVideo: function (uuid, elem, iframeRect) {
		if (ValotaEngine.ChromeExtension.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to resize video when something else is playing";
		}
	},
	stopVideo: function (uuid, elem) {
		if (typeof ValotaEngine.ChromeExtension.videoOwners[uuid] === 'undefined') {
			throw "unknown uuid"; // never played anything
		}
		if (ValotaEngine.ChromeExtension.videoOwners[uuid].elem === null) {
			throw "not running"; // played but not running
		}
		if (ValotaEngine.ChromeExtension.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to stop video when something else is playing";
		}
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem.pause();
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem.removeEventListener("ended", ValotaEngine.ChromeExtension.videoOwners[uuid].videoEndedCB);
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem.removeEventListener("error", ValotaEngine.ChromeExtension.videoOwners[uuid].videoErrorCB);
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem.src = "";
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem.load();
		//document.body.removeChild(ValotaEngine.ChromeExtension.videoOwners[uuid].engineElem);
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem = null;
		ValotaEngine.ChromeExtension.videoOwners[uuid].videoEndedCB = null;
		ValotaEngine.ChromeExtension.videoOwners[uuid].videoErrorCB = null;
		ValotaEngine.ChromeExtension.videoOwners[uuid].videoStartedPlayingCB = null;
		//ValotaEngine.ChromeExtension.videoOwners[uuid].elem = null;
		ValotaEngine.ChromeExtension.videoOwners[uuid].url = null;
	},
	replayVideo: function (uuid, elem, playStartedCB, errorCB) {
		if (ValotaEngine.ChromeExtension.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to replay video that is not playing";
		}
		ValotaEngine.ChromeExtension.videoOwners[uuid].elem.play().then(function () {
			playStartedCB(ValotaEngine.ChromeExtension.videoOwners[uuid].elem.duration, uuid);
			valotaExtensionFS.used(ValotaEngine.ChromeExtension.videoOwners[uuid].name);
		}).catch(function (ev) {
			errorCB(ev, uuid);
		});
		ValotaEngine.ChromeExtension.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.ChromeExtension.videoOwners[uuid].videoErrorCB = errorCB;
	},
	stopVideos: function () {
		for (var uuid in ValotaEngine.ChromeExtension.videoOwners) {
			if (ValotaEngine.ChromeExtension.videoOwners[uuid].elem !== null) {
				try {
					ValotaEngine.ChromeExtension.videoOwners[uuid].videoEndedCB();
					ValotaEngine.ChromeExtension.stopVideo(uuid, ValotaEngine.ChromeExtension.videoOwners[uuid].elem);
				} catch (e) {
					// do nothing now
				}
			}
		}
	}
};

/**
 * filesystem access
 * @type 
 */
var ValotaExtensionFS = function () {
	var valotaFS = null;
	var valotaFiles = {};

	// get us a filesystem
	window.webkitRequestFileSystem(window.PERSISTENT, 1024 * 1024 * 100000, successCB, fsErrorCB); // 100 G

	/**
	 * webkitRequestFileSystem success callback
	 *
	 * @param {type} fs
	 * @returns {undefined}
	 */
	function successCB(fs) {
		valotaFS = fs;
		getFileListAtStartup();
		navigator.webkitPersistentStorage.queryUsageAndQuota(
			function (usedBytes, grantedBytes) {
				console.log('[Engine ChromeExtension] we are using ' + usedBytes + ' of ' + grantedBytes + ' bytes');
			},
			function (e) {
				logError('query usage error: ' + e.toString());
			}
		);
	}

	/**
	 * webkitRequestFileSystem error callback
	 *
	 * @param {type} e
	 * @returns {undefined}
	 */
	function fsErrorCB(e) {
		console.error("[Engine ChromeExtension] no fs");
		logError('unable to open disk, caching not supported! ' + e.toString());
	}

	/**
	 * Clears the given file
	 * @param {string} filename
	 * @returns {undefined}
	 */
	function clear(filename) {
		valotaFiles[filename].loadingStart = null;
		valotaFiles[filename].sendResponse = null;
		valotaFiles[filename].blob = [];
		valotaFiles[filename].complete = false;
	}

	/**
	 * getFileListAtStartup reads the files from disk
	 *
	 * @returns {undefined}
	 */
	function getFileListAtStartup() {
		let dirReader = valotaFS.root.createReader();
		let loopFunc = function () {
			dirReader.readEntries(function (results) {
				if (results.length) {
					for (let i = 0; i < results.length; i++) {
//						console.log("found", results[i].name);
						valotaFiles[results[i].name] = {
							name: results[i].name,
							url: null,
							loadingStart: null,
							status: "disked",
							sendResponse: null,
							blob: [],
							complete: false
						};
						if (localStorage.getItem('valota_extension_file_used_' + encodeKey(results[i].name)) === null) { // probably hard reloaded the display and all the video entries in localStorage are gone, let's set it to be used now
							localStorage.setItem('valota_extension_file_used_' + encodeKey(results[i].name), parseInt(new Date() / 1000));
						}
					}
					loopFunc();
				} else {
					console.log("[Engine ChromeExtension] current cache list", valotaFiles);
				}
			}, (e) => {
				console.error("[Engine ChromeExtension] get file list failed", e);
			});
		};
		loopFunc();
	}

	/**
	 * purgeCache empties the whole cache
	 *
	 * @returns {undefined}
	 */
	function purgeCache() {
		let dirReader = valotaFS.root.createReader();
		let loopFunc = function () {
			dirReader.readEntries(function (results) {
				if (results.length) {
					for (let i = 0; i < results.length; i++) {
						deleteFromCache(results[i].name);
					}
					loopFunc();
				}
			}, (e) => {
				console.error("[Engine ChromeExtension] purge cache failed", e);
			});
		};
		loopFunc();
	}

	/**
	 * listFiles lists all files
	 *
	 * @returns {undefined}
	 */
	function listFiles() {
		console.log("[Engine ChromeExtension] listing files");
		let dirReader = valotaFS.root.createReader();
		let loopFunc = function () {
			dirReader.readEntries(function (results) {
				if (results.length) {
					for (let i = 0; i < results.length; i++) {
						console.log("[Engine ChromeExtension]", results[i].name);
					}
					loopFunc();
				} else {
					console.log("[Engine ChromeExtension] listing files ends");
				}
			}, (e) => {
				console.error("[Engine ChromeExtension] list files failed", e);
			});
		};
		loopFunc();
	}

	/**
	 * Starts to load a URL
	 *
	 * @param {string} filename
	 * @param {string} url
	 * @param {function} resolve
	 * @param {function} reject
	 * @returns {undefined}
	 */
	function loadURL(filename, url, resolve, reject) {
		valotaFiles[filename] = {
			name: filename,
			url: url,
			loadingStart: new Date() / 1000,
			status: "loading",
			blob: [],
			complete: false,
			resolves: [resolve],
			rejects: [reject]
		};
		console.log("[Engine ChromeExtension] loading URL", valotaFiles[filename].url);
		loadURLOverXHR(filename, function (message) {
			resolveAll(message, filename);
		}, function (message) {
			rejectAll(message, filename);
		});
	}

	/**
	 * deletes a file from cache
	 * @param {string} filename
	 * @returns {undefined}
	 */
	function deleteFromCache(filename) {
		console.warn("[Engine ChromeExtension] deleting", filename);
		valotaFS.root.getFile(filename, {create: false}, function (fileEntry) {
			fileEntry.remove(function () {
				console.log('[Engine ChromeExtension] File removed ' + filename);
			}, (e) => {
				console.warn('[Engine ChromeExtension] remove file ' + filename + ': ' + e.toString());
			});
		}, (e) => {
			console.warn('[Engine ChromeExtension] root.getFile in delete ' + filename + ': ' + e.toString());
		});
		localStorage.removeItem('valota_extension_file_used_' + encodeKey(filename));
		delete valotaFiles[filename];
	}

	/**
	 * checks whether file has been used within last 8 days and asks to be deleted if not
	 * @param {type} filename
	 * @returns {undefined}
	 */
	function checkAndDelete(filename) {
		var useTime = localStorage.getItem('valota_extension_file_used_' + encodeKey(filename));
		if (useTime === null) { // not in local storage, delete
			deleteFromCache(filename);
			return;
		}
		if ((new Date() / 1000) - parseFloat(useTime) > 691200) {
			// files accessed more than 8 days ago will be purged
			deleteFromCache(filename);
		}
	}

	/**
	 * checks the cache entries if any can be deleted due to unuse
	 * @returns {undefined}
	 */
	function checkAndPurgeCache() {

		let dirReader = valotaFS.root.createReader();
		let loopFunc = function () {
			dirReader.readEntries(function (results) {
				if (results.length) {
					for (var i = results.length - 1; i >= 0; i--) {
						checkAndDelete(results[i].name);
					}
					loopFunc();
				}
			}, (e) => {
				console.warn('[Engine ChromeExtension] readEntries in purge: ' + e.toString());
			});
		};
		loopFunc();
	}

	/**
	 * encodes a string to be used as a storade key
	 * @param {type} key
	 * @returns {String}
	 */
	function encodeKey(key) {
		return encodeURI(key).toString().replace(/[.]/g, "_");
	}

	/**
	 * marks a file used at the moment this method is called
	 * @param {type} filename
	 * @returns {undefined}
	 */
	function used(filename) {
		localStorage.setItem('valota_extension_file_used_' + encodeKey(filename), parseInt(new Date() / 1000));
		checkAndPurgeCache();
	}

	/**
	 * File write complete
	 * @param {object} e
	 * @param {string} filename
	 * @param {function} resolve
	 * @param {function} reject
	 * @returns {undefined}
	 */
	function fileWriteEndCB(e, filename, resolve, reject) {
		console.log("[Engine ChromeExtension] wrote", filename);
		used(filename);
		valotaFS.root.getFile(filename, {create: false, exclusive: true}, (fileEntry) => {
			fileEntry.file(function (file) {
				valotaFiles[filename].blobUrl = URL.createObjectURL(file);
				console.log("[Engine ChromeExtension] blobUrl created " + valotaFiles[filename].blobUrl);
				resolve({status: "ok", message: "loaded", blobUrl: valotaFiles[filename].blobUrl});
				clear(filename);
			});
		});
		valotaFiles[filename].status = "disked";
		valotaFiles[filename].blob = [];
	}

	/**
	 * creating a writer succeeded
	 * @param {object} fileWriter
	 * @param {string} filename
	 * @param {function} resolve
	 * @param {function} reject
	 * @returns {undefined}
	 */
	function createWriterCB(fileWriter, filename, resolve, reject) {
		fileWriter.onwriteend = (e) => {
			fileWriteEndCB(e, filename, resolve, reject);
		};
		fileWriter.onerror = (e) => {
			reject({message: "write error " + filename + ": " + e.toString()});
		};
		fileWriter.onabort = (e) => {
			reject({message: "write abort " + filename + ": " + e.toString()});
		};
		// Create a new Blob and write it
		fileWriter.write(valotaFiles[filename].blob);
	}

	/**
	 * creating a file succeeded
	 * @param {object} fileEntry
	 * @param {string} filename
	 * @param {function} resolve
	 * @param {function} reject
	 * @returns {undefined}
	 */
	function getFileWriterCB(fileEntry, filename, resolve, reject) {
		fileEntry.createWriter((e) => {
			createWriterCB(e, filename, resolve, reject);
		}, (e) => {
			reject({message: "getFileCB " + filename + ": " + e.toString()});
		});
	}

	/**
	 * xhr loaded
	 * @param {Object} e
	 * @param {String} filename
	 * @param {function} resolve
	 * @param {function} reject
	 * @returns {undefined}
	 */
	function xhrLoadCB(e, filename, resolve, reject) {
		console.log("[Engine ChromeExtension] xhr load", e, filename);
		if (e.target.status === 200) {
			valotaFiles[filename].blob = new Blob([e.target.response], {type: "video/*"});
			// create new file to write to
			valotaFS.root.getFile(filename, {create: true, exclusive: true}, (e2) => {
				getFileWriterCB(e2, filename, resolve, reject);
			}, (e3) => {
				reject({message: "xhr getFile " + filename + ": " + e3.toString()});
			});
		} else {
			reject({message: "xhr status " + e.target.status + " " + filename + " " + e.toString()});
//		console.warn("unable to load", e);
			clear(filename);
		}
	}

	/**
	 * start a xhr to get a file over the network
	 * @param {string} filename
	 * @param {function} resolve
	 * @param {function} reject
	 * @returns {undefined}
	 */
	function loadURLOverXHR(filename, resolve, reject) {
		// put to cache
		var xhr = new XMLHttpRequest();
		xhr.open('GET', valotaFiles[filename].url, true);
		xhr.responseType = 'arraybuffer';
		xhr.onload = (e) => {
			xhrLoadCB(e, filename, resolve, reject);
		};
		xhr.onerror = (e) => {
			reject({message: "xhr onload " + filename + ": " + e});
		};
		xhr.onerror = (e) => {
			reject({message: "xhr onload abort " + filename + ": " + e});
		};
		xhr.send();
	}

	function resolveAll(obj, filename) {
		while (valotaFiles[filename].resolves.length) {
			valotaFiles[filename].resolves.pop()(obj);
		}
		while (valotaFiles[filename].rejects.length) {
			valotaFiles[filename].rejects.pop();
		}
	}

	function rejectAll(obj, filename) {
		while (valotaFiles[filename].resolves.length) {
			valotaFiles[filename].resolves.pop();
		}
		while (valotaFiles[filename].rejects.length) {
			valotaFiles[filename].rejects.pop()(obj);
		}
	}

	return {// public interface
		/**
		 * ask for a new file to be loaded over network
		 *
		 * @param {string} filename a unique filename
		 * @param {string} url url for where to get the data for the file
		 * @param {function} sendResponse
		 */
		loadURL: async function (filename, url) {
			//console.log("loading", filename, valotaFiles[filename]);
			return new Promise((resolve, reject) => {
				if (typeof valotaFiles[filename] !== 'undefined') {
					if (valotaFiles[filename].loadingStart !== null) {
						console.warn("[Engine ChromeExtension] loadURL called for file already caching", filename, valotaFiles[filename]);
						valotaFiles[filename].resolves.push(resolve);
						valotaFiles[filename].rejects.push(resolve);
						return;
					}
					valotaFS.root.getFile(filename, {create: false, exclusive: true}, (fileEntry) => {
						fileEntry.file(function (file) {
							resolve({status: "ok", message: "cached", blobUrl: URL.createObjectURL(file)});
						});
					}, (e3) => {
						reject({message: "getFile in loadURL " + filename + ": " + e3.toString()});
					});
					return;
				}
				loadURL(filename, url, resolve, reject);
			});
		},
		/**
		 * @returns {Boolean} true if ready to accept new cache task, false if not
		 */
		ready: function () {
			return valotaExtensionFS !== null;
		},
		clearCache: function () {
			purgeCache();
		},
		clear: function () {
		},
		list: function () {
			for (var key in valotaFiles) {
				console.log("[Engine ChromeExtension]", valotaFiles[key]);
			}
			listFiles();
		},
		used: function (filename) {
			used(filename);
		},
		deleteFromCache: function(filename){
			deleteFromCache(filename);
		}
	};
};
